import DashboardPage from '../../pages/dashboard/DashboardPage';
import UsersPage from '../../pages/users/UsersPage';
import ManageUsersPage from '../../pages/users/ManageUsersPage';
import ConfigsPage from '../../pages/configs/ConfigsPage';
import ManageConfigsPage from '../../pages/configs/ManageConfigsPage';
import TranslationsPage from '../../pages/translations/TranslationsPage';
import ManageProfilePage from '../../pages/users/ManageProfilePage';
import ManageEventInfoPage from '../../pages/event_info/ManageEventInfoPage';
import PrizesPage from '../../pages/prizes/PrizesPage';
import ManagePrizesPage from '../../pages/prizes/ManagePrizesPage';
import SpeakersPage from '../../pages/speakers/SpeakersPage';
import ManageSpeakersPage from '../../pages/speakers/ManageSpeakersPage';
import AreasPage from '../../pages/areas/AreasPage';
import ManageAreasPage from '../../pages/areas/ManageAreasPage';
import EventsPage from '../../pages/events/EventsPage';
import ManageEventsPage from '../../pages/events/ManageEventsPage';
import PointsPage from '../../pages/points/PointsPage';
import ManagePointsPage from '../../pages/points/ManagePointsPage';
import DynamicPages from '../../pages/dynamicPages/DynamicPages';
import ManageDynamicPages from '../../pages/dynamicPages/ManageDynamicPages';
import SharePage from '../../pages/share/SharePage';
import EmailTemplatesPage from '../../pages/email_templates/EmailTemplatesPage';
import ManageEmailTemplatesPage from '../../pages/email_templates/ManageEmailTemplatesPage';
import CompaniesPage from '../../pages/companies/CompaniesPage';
import ManageCompaniesPage from '../../pages/companies/ManageCompaniesPage';

export default [
  {
    path: '/',
    component: DashboardPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/my-profile',
    component: ManageProfilePage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users',
    component: UsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/add',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/users/:id',
    component: ManageUsersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/companies',
    component: CompaniesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/companies/add',
    component: ManageCompaniesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/companies/:id',
    component: ManageCompaniesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs',
    component: ConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs/edit',
    component: ManageConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/translations',
    component: TranslationsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/event-info',
    component: ManageEventInfoPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/prizes',
    component: PrizesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/prizes/add',
    component: ManagePrizesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/prizes/:id',
    component: ManagePrizesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/speakers',
    component: SpeakersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/speakers/add',
    component: ManageSpeakersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/speakers/:id',
    component: ManageSpeakersPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/areas',
    component: AreasPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/areas/add',
    component: ManageAreasPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/areas/:id',
    component: ManageAreasPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/events',
    component: EventsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/events/add',
    component: ManageEventsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/events/:id',
    component: ManageEventsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/points',
    component: PointsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/points/:id',
    component: ManagePointsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/dynamic-pages',
    component: DynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/dynamic-pages/:id',
    component: ManageDynamicPages,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/share/event/:id',
    component: SharePage,
    isAuthenticated: false
  },
  {
    path: '/email-templates',
    component: EmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-templates/:id',
    component: ManageEmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  }


];
