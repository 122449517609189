import PropTypes from "prop-types";
import React from "react";
import { InputDiv, InputLabelDiv, InputField, NotesText } from "./InputStyles";

const TextInput = ({
  input,
  meta,
  prefix,
  size,
  suffix,
  type,
  small,
  onPressEnter,
  label,
  first,
  placeholder,
  disabled,
  notesText
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv small={small}>{label}</InputLabelDiv>}
      <InputField
        disabled={disabled}
        prefix={prefix}
        size={size}
        suffix={suffix}
        type={type}
        value={input.value}
        onChange={input.onChange}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        error={showError ? 1 : 0}
      />
      { notesText && <NotesText>{ notesText }</NotesText> }
    </InputDiv>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func,
  notesText: PropTypes.string
};

export default TextInput;
