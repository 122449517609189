import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { EventTypes, EventSubTypes } from '../../infra/utils/EventTypes';
import getEventDates from '../../infra/utils/EventDates';

class EventsFilters extends Component {
  state = {
    search: '',
    type: '',
    subtype: '',
    date: '',
    area: '',
    speaker: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map((key) => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  render() {
    const { areas, speakers, eventInfo } = this.props;
    const { search, type, subtype, date, area, speaker } = this.state;
    
    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={6}>
            <TextInput
              input={{
                value: search,
                onChange: (event) =>
                  this.onInputChange('search', event.target.value, 1000),
              }}
              meta={{ valid: true }}
              type='text'
              label='Pesquisar'
              placeholder='Procurar por nome ou localização'
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              label='Tipo de Evento'
              allowClear
              placeholder='Escolher tipo'
              data={EventTypes}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: type,
                onChange: value => this.onInputChange('type', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              label='Subtipo de Evento'
              allowClear
              placeholder='Escolher subtipo'
              data={EventSubTypes}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: subtype,
                onChange: value => this.onInputChange('subtype', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              label='Data'
              allowClear
              placeholder='Escolher data'
              data={getEventDates(eventInfo)}
              dataKey='_id'
              dataLabel='date'
              input={{
                value: date,
                onChange: value => this.onInputChange('date', value)
              }}
              meta={{valid: true}}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              label='Área'
              allowClear
              placeholder='Escolher área'
              data={areas}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: area,
                onChange: value => this.onInputChange('area', value)
              }}
              meta={{valid: true}}
              translatable={true}
            />
          </Col>
          <Col xs={24} md={12} lg={6}>
            <SelectInput
              label='Orador'
              allowClear
              placeholder='Escolher orador'
              data={speakers}
              dataKey='_id'
              dataLabel='name'
              input={{
                value: speaker,
                onChange: value => this.onInputChange('speaker', value)
              }}
              meta={{valid: true}}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

export default EventsFilters;
