export const YearTypes = [
  { _id: 1, name: '1' },
  { _id: 2, name: '2' },
  { _id: 3, name: '3' },
  { _id: 4, name: '4' },
  { _id: 5, name: '5' },
  { _id: 6, name: 'Já concluí este curso' }
];

export const getYearType = (value) => {
  if(Number.isInteger(value) && value > 0 && YearTypes[value - 1]) return YearTypes[value - 1].name;
  return null;
};