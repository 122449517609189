import client from '../config/AxiosConfig';

export const GetPrize = async id => await client.get(`/prizes/${id}`);

export const GetPrizes = async () => await client.get(`/prizes`);

export const CreatePrize = async (data) => await client.post(`/prizes`, data);

export const UpdatePrize = async (id, data) => await client.put(`/prizes/${id}`, data);

export const TogglePrizeState = async (id, data) => await client.put(`/prizes/${id}/toggle`, data);

export const DeletePrize = async (id) => await client.delete(`/prizes/${id}`);