import LoginPage from "../../pages/auth/LoginPage";
import RecoverAccountPage from "../../pages/auth/RecoverAccountPage";
import RecoverPasswordPage from "../../pages/auth/RecoverPasswordPage";
import ValidateAccountPage from "../../pages/auth/ValidateAccountPage";
import DefinePasswordPage from "../../pages/auth/DefinePasswordPage";
import LeaderBoardPage from "../../pages/leaderboard/LeaderBoardPage";

export default [
  {
    path: "/login",
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: "/recover-account",
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: "/recover-password",
    component: RecoverPasswordPage,
    isAuthenticated: false
  },
  {
    path: "/validate",
    component: ValidateAccountPage,
    isAuthenticated: false
  },
  {
    path: "/define-password",
    component: DefinePasswordPage,
    isAuthenticated: false,
  },
  {
    path: '/leaderboard',
    component: LeaderBoardPage,
    isAuthenticated: false,
    role: 1,
  }
];
