import styled from 'styled-components';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;

export const TabsContainer = styled(Tabs)`
  margin-top: 30px;

  & .ant-tabs-nav-scroll {
    padding: 0px 20px;
  }

  & .ant-tabs-nav-container {
    font-size: 16px;
  }
`;

export const Tab = styled(TabPane)`

`;


export const TableContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f5f5f5;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TableTitle = styled.div`
  text-align: center;
  padding: 8px 0px;
  width: 50%;
  font-size: 15px;
  font-weight: bold;
`;

export const TableContent = styled.div`
  background-color: #ffffff;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TableValue = styled.div`
  text-align: center;
  padding: 8px 0px;
  width: 50%;

  & > div {
    margin-top: 0px;
  }
`;

export const StateLabel = styled.div`
  background-color: ${({ active }) => active ? '#4CAF50' : '#f44336'};
  display: inline-block;
  width: auto;
  padding: 2px 5px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
`;