import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, initialize, reset, FieldArray } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetAreasList,
} from '../../infra/requests/AreasRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { FormContainer, BaseForm, SpinLoading, Separator } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import ImageInput from '../../components/generic/inputs/ImageInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import { CompanyTypes } from '../../infra/utils/CompanyTypes';
import { SponsorTypes } from '../../infra/utils/SponsorTypes';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import SelectTagsInput from '../../components/generic/inputs/SelectTagsInput';
import {
  TabsContainer,
  Tab
} from '../users/UserStyles';
import YearAccess from '../users/components/YearAccess';
import { CreateCompany, GetCompany, UpdateCompany } from '../../infra/requests/CompaniesRequests';

const validations = FormValidator.make({
  name: 'required',
});

class ManageCompaniesPage extends Component {
  state = {
    isNew: false,
    loading: true,
    areas: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    const areas = await GetAreasList();

    CloseMenu();

    if(params.id) {
      const result = await GetCompany(params.id);
      dispatch(
        initialize('manage_companies_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        areas: areas?.data || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        areas: areas?.data || [],
      });
    }
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch,
      } = this.props;
      const { isNew } = this.state;

      const payload = FlattenToFormData(values);
      const request = isNew ? await CreateCompany(payload) : await UpdateCompany(params.id, payload);

      this.setState({ loading: false });
      
      if(request.success) {
        if(createNewAfter) {
          dispatch(reset('manage_companies_form'));
          return history.push('/companies/add');
        } 
        else {
          return history.push('/companies');
        }
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/companies');
  };

  renderDetails = () => {
    const { areas } = this.state;

    return (
      <React.Fragment>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label='Nome *'
              placeholder='Nome da empresa'
            />
          </Col>
          <Col xs={24} lg={12}>
            <Field
              component={TextInput}
              name='email'
              type='email'
              label='Email'
              placeholder='Email de contacto na empresa'
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Field
              component={SelectInput}
              name={'company_type'}
              label={'Tipo de entidade'}
              placeholder={'Escolha o tipo'}
              type='select'
              data={CompanyTypes}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col> 
          <Col xs={24} lg={12}>
            <Field
              component={SelectInput}
              name={'sponsor_tag'}
              label={'Categoria de patrocinador'}
              placeholder={'Escolha a categoria'}
              type='select'
              data={SponsorTypes}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
            />
          </Col> 
          </Row>  
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='event_location'
                  type='text'
                  label='Localização no evento'
                  placeholder='Lozalização no evento'
                />
              </Col>
            </Row>
            <Separator/>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={ImageInput}
                  name='image'
                  label='Logo da empresa'
                  ratio={1.0}
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TextAreaInput}
                  name='description'
                  label='Descrição da empresa'
                  placeholder='Descrição da empresa'
                  minRows={9}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='responsible'
                  type='text'
                  label='Nome da pessoa responsável'
                  placeholder='Nome da pessoa responsável'
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='responsible_job'
                  type='text'
                  label='Cargo/Função'
                  placeholder='Cargo/Função da pessoa responsável'
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='contact'
                  type='text'
                  label='Contacto da pessoa responsável'
                  placeholder='Contacto da pessoa responsável'
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='responsible_email'
                  type='text'
                  label='Email da pessoa responsável'
                  placeholder='Email da pessoa responsável'
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='city'
                  type='text'
                  label='Cidade'
                  placeholder='Cidade'
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='website'
                  type='text'
                  label='Website'
                  placeholder='Website'
                  noteText='O website deve começar por http:// ou https://'
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24} lg={12}>
                <Field
                  component={CheckboxInput}
                  name={`has_jobs`}
                  label={'Tem oportunidades de emprego?'}
                  inlineCheckbox={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24} lg={12}>
                <Field
                  component={CheckboxInput}
                  name={`is_codepoint`}
                  label={'É a Codepoint?'}
                  inlineCheckbox={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24}>
                <Field
                  name='areas'
                  label={'Áreas de atuação'}
                  multiple={true}
                  component={SelectTagsInput}
                  options={areas}
                  translatable={true}
                />
              </Col>
            </Row>
          </React.Fragment>
    );
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, user } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Empresas', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer style={{ marginBottom: '50px' }}>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            {
              isNew ?
              this.renderDetails()
              :
              <TabsContainer defaultActiveKey='details'>
                <Tab
                  tab='Detalhes'
                  key='details'
                >
                  {this.renderDetails()}
                </Tab>
                <Tab
                  tab='Acesso APP'
                  key='access'
                >
                  <Row gutter={24}>
                    <Col xs={24}>
                      <FieldArray
                        component={YearAccess}
                        name='active_fo'
                      />
                    </Col>
                  </Row>
                </Tab>
              </TabsContainer>
            }
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCompaniesPage = reduxForm({
  form: 'manage_companies_form',
  validate: validations,
})(ManageCompaniesPage);

const mapStateToProps = (state) => ({
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageCompaniesPage));