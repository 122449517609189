import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col, Icon, notification } from 'antd';
import {
  GetSpeakersList
} from '../../../infra/requests/SpeakersRequests';
import SelectInput from '../../../components/generic/inputs/SelectInput';
import { 
  SpeakerContainer,
  SpeakerContent,
  SpeakerName,
  SpeakerJob,
  SpeakerDelete
} from '../EventsStyles';

const SpeakersComponent = ({ fields, subtype, users }) => {
  const [speakers, SetSpeakers] = useState(users || []);
  //Variable to control wheter the component is mount or not to prevent the error "Warning: Can't perform a React state update on an unmounted component"
  let componentMounted = true;

  useEffect(() => {
    async function fetchData() {
      const { data } = await GetSpeakersList();
      if(componentMounted) SetSpeakers(data);
    }
    if (!speakers) fetchData();
    return () => {
      componentMounted = false;
    }
  }, [componentMounted]);

  const addSpeaker = id => {
    const speakersSelected = fields.getAll() || [];

    // if(parseInt(subtype) === 2 && speakersSelected?.length > 0) {
    //   return notification.error({
    //     message: 'Apenas pode selecionar um Orador para uma Talk!'
    //   });
    // }

    const speaker = speakers.find(x => x._id === id);

    const exists = speakersSelected.findIndex(elem => elem?._id === id);

    if(speaker && exists === -1) {
      fields.unshift({ ...speaker });
    }
  };

  const GetSpeakerName = index => {
    const field = fields.get(index);
    return field?.name || 'Orador removido';
  };

  const GetSpeakerJob = index => {
    const field = fields.get(index);

    let job = '';
    if(field?.job_title) job = field?.job_title;

    if(field?.company) {
      if(job) job = `${job} @ ${field?.company}`;
      else job = `@ ${field?.company}`;
    }

    return job;
  };

  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col xs={24}>
          <SelectInput
            label='Selecionar orador'
            placeholder='Selecione um ou mais oradores da lista'
            data={speakers}
            meta={{}}
            input={{value: '', onChange: addSpeaker}}
          />
        </Col>
      </Row>
      {
        fields.map((field, index) => (
          <Row gutter={24} key={index} style={{ marginTop: '15px' }}>
            <Col xs={24}>
              <SpeakerContainer>
                <SpeakerContent>
                  <SpeakerName>{GetSpeakerName(index)}</SpeakerName>
                  <SpeakerJob>{GetSpeakerJob(index)}</SpeakerJob>
                </SpeakerContent>
                <SpeakerDelete>
                  <Icon type='delete' onClick={() => fields.remove(index)}/>
                </SpeakerDelete>
              </SpeakerContainer>
            </Col>
          </Row>
        ))
      }
    </React.Fragment>
  );
};

SpeakersComponent.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default SpeakersComponent;