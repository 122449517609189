import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetConfig,
  UpdateConfigType,
} from '../../infra/requests/ConfigsRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { TransformToFormData } from '../../infra/services/formdata/TransformToFormData';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';

const configID = '60c9f6be4493e718a056a6ca';

const validations = FormValidator.make({
  email_notifications: 'required|email'
});

class ManageConfigsPage extends Component {
  state = {
    loading: true,
  };

  componentDidMount = async () => {
    try {
      const { dispatch, CloseMenu } = this.props;
      
      CloseMenu();

      const configurations = await GetConfig(configID);
      dispatch(
        initialize('manage_config_form', {
          ...configurations.data,
        })
      );

      this.setState({
        loading: false,
      });
    } 
    catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { history } = this.props;

      const formDataValues = TransformToFormData(values);
      await UpdateConfigType(configID, formDataValues);

      return history.push('/configs');
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/configs');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Informações Gerais', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name='email_notifications'
              type='text'
              label={'Email para Notificações *'}
              placeholder={'Introduza o(s) emails(s) para receber as notificações'}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageConfigsPage = reduxForm({
  form: 'manage_config_form',
  validate: validations,
})(ManageConfigsPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageConfigsPage));