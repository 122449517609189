import client from "../config/AxiosConfig";

export const GetCompany = async id => await client.get(`/companies/${id}`);

export const GetCompanies = async (currentPage, limit, filters) => await client.get(`/companies/${currentPage}/${limit}?filter=${filters}`);

export const UpdateCompany = async (id, data) => await client.put(`/companies/${id}`, data);

export const CreateCompany = async data => await client.post('/companies', data);

export const DeleteCompany = async id => await client.delete(`/companies/${id}`);

export const SendCompanyReport = async (data) => await client.post('/companies/send_report', data);

