import styled from 'styled-components';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;

export const TabsContainer = styled(Tabs)`
  margin-top: 30px;

  & .ant-tabs-nav-scroll {
    padding: 0px 20px;
  }

  & .ant-tabs-nav-container {
    font-size: 16px;
  }
`;

export const Tab = styled(TabPane)`

`;


export const TableContainer = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #f5f5f5;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TableTitle = styled.div`
  text-align: center;
  padding: 8px 0px;
  width: 50%;
  font-size: 15px;
  font-weight: bold;
`;

export const TableContent = styled.div`
  background-color: #ffffff;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TableValue = styled.div`
  text-align: center;
  padding: 8px 0px;
  width: 50%;

  & > div {
    margin-top: 0px;
  }
`;

export const StateLabel = styled.div`
  background-color: ${({ active }) => active ? '#4CAF50' : '#f44336'};
  display: inline-block;
  width: auto;
  padding: 2px 5px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 13px;
`;

export const SpeakerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export const SpeakerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  flex-shrink: 0;
  border-right: 1px solid #d9d9d9;
`;

export const SpeakerName = styled.div`

`;

export const SpeakerJob = styled.div`
  opacity: 0.6;
`;

export const SpeakerDelete = styled.div`
  width: 20%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  & i {
    cursor: pointer;
    padding: 5px;
  }
`;

export const TypeLabel = styled.div`
  padding: 3px 5px;
  border-radius: 5px;
  background-color: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  color: ${(p) => (p.fontColor ? p.fontColor : 'rgba(0, 0, 0, 0.65)')};
  display: inline-block;
  width: auto;
`;