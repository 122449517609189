import React from "react";
import PropTypes from "prop-types";

import {
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  HeaderButtonsContainer,
} from "../../styles/BasicStyles";
import BaseButton from "../generic/buttons/BaseButton";

const ManageFormHeader = ({ buttons, titles }) => {
  return (
    <HeaderContainer>
      <HeaderTitle buttons={buttons.length}>
        <PageTitle>{titles[titles.length - 1]}</PageTitle>
        {titles.map((title, index) => {
          if (titles.length === index + 1) {
            return title;
          } else {
            return `${title} > `;
          }
        })}
      </HeaderTitle>
      <HeaderButtonsContainer buttons={buttons.length}>
        {buttons.map((button, index) => (
          <BaseButton
            key={index}
            type={button.type ? button.type : "default"}
            icon={button.icon ? button.icon : "save"}
            text={button.text ? button.text : "Gravar"}
            onClick={button.onClick}
            disabled={button.disabled}
          />
        ))}
      </HeaderButtonsContainer>
    </HeaderContainer>
  );
};

ManageFormHeader.propTypes = {
  buttons: PropTypes.array,
  titles: PropTypes.array,
};

export default ManageFormHeader;
