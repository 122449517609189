import React, { Component } from 'react';
import { Popconfirm, Icon, notification } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  SendCompanyReport,
} from '../../infra/requests/CompaniesRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import UsersFilters from './CompaniesFilters';
import moment from 'moment';
import { StateLabel } from '../users/UserStyles';
import { DeleteCompany, GetCompanies } from '../../infra/requests/CompaniesRequests';
import EmployeesGridColumns from './components/EmployeesGridColumns';

class CompaniesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '20%'
      },
      {
        title: 'Estado',
        key: 'active',
        render: (data) => this.renderState(data),
        width: '10%'
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover esta empresa?'
                onConfirm={() => this.removeCompany(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
            <TableButton onClick={(e) => {e.stopPropagation(); this.sendReport(data)}}>
              <Icon style={{ marginRight: 10 }} type='mail' />
              {'Enviar informação por email'}
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/companies/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeCompany = async (data) => {
    try {
      this.setState({ loading: true });
      await DeleteCompany(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  sendReport = async (data) => {
    try {
      this.setState({ loading: true });
      await SendCompanyReport(data);
      notification.success({
        message: "Informação enviada com sucesso!",
      });
      this.setState({ loading: false})
    } catch (e) {
      this.setState({ loading: false })
      notification.error({
        message: "Ocorreu um erro",
      });
      console.error(e);
    }
  }

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetCompanies(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderState = (data) => {
    if(parseInt(data.type) !== 1) {
      const year = moment.utc().year();

      if(data.active_fo?.length > 0) {
        const state = data.active_fo.find(elem => elem.year === year);
        if(state) {
          return (
            <StateLabel active={state.active ? true : false}>{year} - {state.active ? 'Ativo' : 'Inativo'}</StateLabel>
          );
        }
      }
      return (
        <StateLabel active={false}>{year} - Inativo</StateLabel>
      );
    }
    return (
      <StateLabel active={data.active ? true : false}>{data.active ? 'Ativo' : 'Inativo'}</StateLabel>
    );
  };

  renderValid = (data) => {
    if(!data.accepted) {
      return (
        <StateLabel active={false}>Inativo</StateLabel>
      );
    }
    return (
      <StateLabel active={true}>Ativo</StateLabel>
    );
  };

  handleOnPressSubRow = (record) => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  renderExpanded = (record) => {
    return (
      <>
        Colaboradores
        <Table
          columns={EmployeesGridColumns}
          rows={record.employees}
          showHeader={true}
          emptyText={'Não existem Colaboradores inseridos para esta empresa!'}
          rowKey={"_id"}
          onPressRow={this.handleOnPressSubRow}
        />
      </>
    );
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Empresas</PageTitle>
            {`Empresas`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/companies/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UsersFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Empresas inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            expandedRowRender={(item) => this.renderExpanded(item)}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default CompaniesPage;