export const UserTypes = [
  { _id: 1, name: 'Administrador' },
  { _id: 2, name: 'Participante' },
  // { _id: 3, name: 'Empresa' },
  { _id: 4, name: 'Voluntário' },
  { _id: 3, name: 'Colaborador' }
];

export const getUserType = (value) => {
  if(Number.isInteger(value) && value > 0 && UserTypes.find(v => v._id == value)) return UserTypes.find(v => v._id == value).name;
  return null;
};

export const UserSorts = [
  { _id: 'points', name: 'Pontos descrescente' }
];
