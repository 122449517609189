import { TOGGLE_MENU, CLOSE_MENU } from '../ActionsType';

export function MenuToggle(value) {
  return {
    type: TOGGLE_MENU,
    value
  };
}

export function CloseMenu() {
  return {
    type: CLOSE_MENU
  };
}