import styled from 'styled-components';
import { Col, Row } from 'antd';

export const RowDashboard = styled(Row)`
`;

export const ColDashboard = styled(Col)`
  height: 160px;
`;

export const ColDashboardSmall = styled(Col)`
  height: 100px;
`;

export const Block = styled.div`
  background-color: #fff;
  border-radius: 8px;
  Border: 2px solid ${({ theme }) => theme.menuBorderColor};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 16px;
`;

export const TitleYear = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const TitleData = styled.span`
  font-size: 48px;
  color: ${({ theme }) => theme.secondaryColor};
  margin-bottom: 0px;
`;

export const LeaderBoardContent = styled.div`
    margin: 0px auto;
    padding: 40px;
    max-width: 900px;
`;

export const TitleSmall = styled.h1`
  font-size: 18px;
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 16px;
`;

export const TitleDataSmall = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.secondaryColor};
  margin-bottom: 0px;
`;