import client from '../config/AxiosConfig';

export const GetSpeaker = async id => await client.get(`/speakers/${id}`);

export const GetSpeakers = async (currentPage, limit, filters) => await client.get(`/speakers/${currentPage}/${limit}?filter=${filters}`);

export const CreateSpeaker = async (data) => await client.post(`/speakers`, data);

export const UpdateSpeaker = async (id, data) => await client.put(`/speakers/${id}`, data);

export const DeleteSpeaker = async (id) => await client.delete(`/speakers/${id}`);

export const GetSpeakersList = async () => await client.get(`/speakers`);