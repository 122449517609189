import moment from 'moment';

const getEventDates = (eventInfo) => {
  const eventDates = [];

  if(eventInfo) {
    eventInfo.dates.forEach((e, index) => {
      eventDates.push({
        _id: index + 1,
        date: `${index + 1}º Dia - ${moment.utc(e).format('DD/MM/YYYY')}`
      });
    })

  }

  return eventDates;
}

export default getEventDates;