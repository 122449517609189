import styled from 'styled-components';
import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  DatePicker,
  Icon,
  Collapse,
  Cascader,
  Switch,
  Calendar,
  Radio,
  TimePicker
} from 'antd';
const { TextArea } = Input;
const { Panel } = Collapse;

const defineBorderColor = (props) => {
  if (props.error) return props.theme.inputErrorColor;
  if (props.active) return props.theme.primaryColor;
  return props.theme.inputBorderColor;
};

export const InputDiv = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${(p) => (p.first ? '0px' : '10px')};
`;

export const SampleButtonSpace = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
`;

export const SampleInputSpace = styled.div`
  width: 100%;
  margin-top: ${(p) => (p.first ? '0px' : '10px')};
  height: 64px;
`;

export const InputLabelDiv = styled.div`
  display: inline-block;
  font-size: ${({ small }) => (small ? '12px' : '16px')};
  width: ${p => p.inlineCheckbox ? 'auto' : '100%'};
  ${({ error }) => error && 'color: red'};
  margin-top: ${({ sublabel }) => (sublabel ? '10px' : 0)};
  font-weight: ${p => p.importantLabel ? 'bold' : 'normal'};
`;

export const InputField = styled(Input)`
  width: 100%;
  &&& {
    margin-bottom: ${({ language }) => (language ? 3 : 0)}px;
  }

  &&&.ant-input-affix-wrapper .ant-input-prefix {
    ${({ language, theme }) =>
      language
        ? `
    width: 70px;
    height: 100%;
    padding-right: 5px;
    border-right: 1px solid ${theme.inputBorderColor};
    `
        : ''};
  }
  &&&.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: ${({ language }) => (language ? 90 : 30)}px;
  }

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${(p) => p.theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const StyledSelectInput = styled(Select)`
  width: 100%;

  ${(p) =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`;

export const CalendarInputField = styled(Calendar)`
  width: 100%;
`;

export const TextAreaInputField = styled(TextArea)`
  width: 100%;

  &:focus-within,
  &:hover {
    ${(p) =>
      p.error && `border: 1px solid ${p.theme.inputErrorColor} !important;`};
  }

  ${(p) =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const InputNumberField = styled(InputNumber)`
  width: 100% !important;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `.ant-input-number-input{
        border: 1px solid ${p.theme.inputErrorColor};
      }
  `}
`;

export const CheckboxField = styled(Checkbox)`
  margin-left: ${p => p.inlineCheckbox ? '15px' : '0px'};
`;

export const SwitchField = styled(Switch)``;

export const LanguagePill = styled.div`
  display: inline-block;
  margin: 10px 10px 0 0;
  padding: 6px 10px;
  border: 1px solid ${(props) => defineBorderColor(props)};
  color: ${({ active }) => (active ? 'white' : 'rgba(0, 0, 0, 0.65)')};
  background-color: ${({ active, theme }) =>
    active ? theme.primaryColor : 'transparent'};
  cursor: pointer;
  border-radius: 3px;
`;

export const Flag = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const DateInputField = styled(DatePicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const RatingInputBox = styled.div`
  display: inline-block;
  width: 100%;
`;

export const RateImage = styled.img`
  margin: 5px;
  width: 30px;
  cursor: pointer;
`;

export const ImageInputSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: ${({ auto }) => (auto ? 0 : 200)}px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => defineBorderColor(props)};
  cursor: pointer;
  ${({ filled }) => (filled ? 'border: none' : '')}
`;

export const ImageMessage = styled.div`
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
  font-size: 18px;
  margin-top: 30px;
`;

export const ImageIcon = styled(Icon)`
  font-size: 36px;
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
`;

export const LanguagesComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin: 10px 0;
`;

export const AddLangButton = styled.div`
  display: inline-block;
  width: 300px;
  margin-top: 10px;
`;

export const DeleteLangIcon = styled.div`
  display: inline-block;
  position: relative;
`;

export const FormCollapse = styled(Collapse)`
  &&.ant-collapse-header {
    color: red;
  }
`;

export const FormPanel = styled(Panel)`
  &&& {
    .ant-collapse-header {
      color: ${({ error }) => (error ? 'red !important' : '')};
    }
    .ant-collapse-content-box {
      padding: 5px;
    }
  }
`;

export const ImageSlideComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 20px;

  .ant-carousel {
    margin-top: 10px;
    .slick-slide {
      position: relative;
    }
  }
`;

export const ImageSlideButton = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;
`;

export const RemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  font-size: 50px;
`;

export const EmptySlide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  border: 1px solid
    ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};

  ${AddIcon} {
    color: ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};
  }
`;

export const MapSearchInput = styled(Input)`
  &&& {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 350px;
  }
`;

export const PreviewVideo = styled.div`
  display: inline-block;
  width: 100%;
  height: 400px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export const FileDropSection = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.inputBorderColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;

  i {
    font-size: 50px;
  }

  &:focus,
  &:hover {
    border: 1px solid ${({ theme }) => theme.primaryColor};
    outline: none;
  }
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const FilesContent = styled.div`
  position: relative;
  width: 100%;
`;

export const FilesLabel = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #323232;
  padding-right: 30px;
  flex-shrink: 0;
`;

export const ImageFilesSection = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  background: ${(p) => p.theme.primaryGray};
  border-radius: 6px;
  padding: 12px 10px;
  padding-left: 40px;
  cursor: pointer;
  border: 1px solid ${(p) => (p.error ? p.theme.errorColor : 'transparent')};

  & button {
    position: absolute;
    top: 0;
    right: 0;
    height: 49px !important;
    padding: 0px 25px !important;
  }
`;

export const FilesErrorLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${(p) => p.theme.errorColor};
  position: absolute;
  bottom: -20px;
  left: 0;
`;

export const ImageName = styled.div`
  color: #323232;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: calc(100% - 150px);
  height: 24px;
  overflow: hidden;
  opacity: ${(p) => (p.isPlaceholder ? '0.4' : '1')};
`;

export const ImageClearIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const ImageInputContent = styled.div`
  position: relative;
`;

export const CurrencyInputStyle = styled.input`
  display: inline-block;
  width: 100%;
  border: 1px solid
    ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};
  background: transparent;
  box-sizing: border-box;
  margin: 0;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  transition: all 0.3s;

  &:focus {
    border: 1px solid ${({ theme }) => theme.inputFocusColor};
    outline: 0;
    box-shadow: 0 0 0 2px rgba(188, 146, 36, 0.2);
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.inputFocusColor};
  }
`;

export const ColourInput = styled(InputField)`
  float: left !important;
  width: calc(100% - 40px) !important;
`;

export const ColourBox = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  float: right;
  background-color: ${({ colour }) => colour};
  border: 1px solid black;
`;

export const StyledCascadeInput = styled(Cascader)`
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-select-selection {
      border: 1px solid ${(p) => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`;

export const DynamicImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const FilesGallerySection = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 500px;
`;

export const NotesText = styled.div`
  font-size: 13px;
  padding-top: 5px;
`;

export const RadioGroup = styled(Radio.Group)`
  margin-top: 10px;

  &.ant-radio-group {
    & .ant-radio-wrapper {
      display: ${(p) => (p.isVertical ? 'block' : 'inline-block')};
      margin-bottom: ${(p) => (p.isVertical ? '10px' : '0px')};

      & .ant-radio-inner {
        border: 1px solid
          ${(p) => (p.error ? p.theme.inputErrorColor : 'rgba(0, 0, 0, 0.6)')};
      }
    }
  }
`;

/********************************************************* SELECT TAGS INPUT *********************************************************/

export const PillsSection = styled.div`
  display: inline-block;
  width: 100%;
`;

export const Pill = styled.div`
  cursor: pointer;
  display: inline-block;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  background: ${({ isActive }) => (isActive ? "#d9d9d9" : "transparent")};
  padding: 5px 30px;
  margin-right: 10px;
  margin-top: 8px;
  transition: color 0.3s linear, border 0.3s linear;

  &:hover {
    border: 1px solid ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColor};
  }
`;

/********************************************************* FILES INPUT *********************************************************/

export const FileSection = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const ResponsePill = styled.div`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  background: ${({ transparent }) => (transparent ? 'transparent' : '#f5f5f5')};
  border-radius: 4px;
  border: 2px solid #f5f5f5;
  border-top-right-radius: ${({ single }) => (single ? 4 : 0)}px;
  border-bottom-right-radius: ${({ single }) => (single ? 4 : 0)}px;
  white-space: pre-line;
  position: relative;
  margin-top: 5px;
`;

export const ExtraPill = styled.div`
  border: 2px solid #f5f5f5;
  background: white;
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  background-color: ${({ remove }) =>
    remove
      ? 'rgba(255, 0, 0, 0.2)'
      : ({ open }) => (open ? 'rgba(97, 149, 237, 0.2)' : null)};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-bottom-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-right: ${({ last }) => (last ? 2 : 0)}px solid #f5f5f5;
  cursor: ${({ link }) => (link ? 'pointer' : 'default')};
`;

/********************************************************* SUN EDITOR INPUT *********************************************************/

export const SunDraftWrapper = styled.div`
  .sun-editor {
    border: 1px solid ${p => p.error ? p.theme.inputErrorColor : '#dadada'};

    & .se-toolbar {
      border-bottom: 1px solid #dadada;
      outline: 0;
    }
  }

  .se-file-container {
    position: relative;

    & .se-file-img-remove {
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 999;
      width: 20px;
      height: 20px;
      background-color: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      border: 2px solid #333333;
      
      & span {
        color: #333333;
        font-size: 15px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }

      & svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        box-shadow: 0 0 0 0.1rem #3288ff;
      }
    }
  }
`;

/********************************************************* TIME INPUT *********************************************************/

export const TimeInputField = styled(TimePicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;