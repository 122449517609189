import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { notification } from 'antd';
import {
  GetProfile,
  UpdateProfile
} from '../../infra/requests/UsersRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import { 
  FormContainer, 
  BaseForm, 
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { SaveUser } from '../../redux/User/user.actions';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';

const validations = FormValidator.make({
  name: 'required',
  email: 'required|email'
});

class ManageProfilePage extends Component {
  state = {
    loading: true,
    type: 0
  };

  componentDidMount = async () => {
    await this.loadDetail();
  };

  loadDetail = async () => {
    const { dispatch, CloseMenu } = this.props;

    CloseMenu();

    const result = await GetProfile();
    dispatch(
      initialize('manage_profile_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      type: result?.data?.type || 0
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { user, dispatch } = this.props;

      const payload = FlattenToFormData(values);
      const request = await UpdateProfile(user._id, payload);

      this.setState({ loading: false });

      if(request.success) {
        dispatch(SaveUser(request.data));

        notification.success({
          message: "Sucesso!",
          description: "Perfil atualizado com sucesso."
        });

        return await this.loadDetail();
      }
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['O Meu Perfil', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label='Nome *'
              placeholder='Primeiro e último nome'
            />
            <Field
              component={TextInput}
              name='email'
              type='email'
              label='Email *'
              placeholder='Email do utilizador'
            />
            <Field
              component={TextInput}
              name='contact'
              type='text'
              label='Contacto'
              placeholder='Contacto do utilizador'
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageProfilePage = reduxForm({
  form: 'manage_profile_form',
  validate: validations,
})(ManageProfilePage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu, SaveUser }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageProfilePage));