import moment from "moment";
import React from "react";
import { StateLabel } from "../../users/UserStyles";

const EmployeesGridColumns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    width: '30%'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '30%'
  },
  {
    title: 'Estado',
    key: 'active',
    render: (data) => {
      if(parseInt(data.type) !== 1) {
        const year = moment.utc().year();
    
        if(data.active_fo?.length > 0) {
          const state = data.active_fo.find(elem => elem.year === year);
          if(state) {
            return (
              <React.Fragment>
                <StateLabel active={state.active ? true : false}>{year} - {state.active ? 'Ativo' : 'Inativo'}</StateLabel>
              </React.Fragment>
            );
          }
        }
        return (
          <StateLabel active={false}>{year} - Inativo</StateLabel>
        );
      }
      return (
        <StateLabel active={data.active ? true : false}>{data.active ? 'Ativo' : 'Inativo'}</StateLabel>
      );
    },
    width: '10%'
  },
  {
    title: 'Válido',
    key: 'accepted',
    render: (data) => {
      if(!data.accepted) {
        return (
          <StateLabel active={false}>Inativo</StateLabel>
        );
      }
      return (
        <StateLabel active={true}>Ativo</StateLabel>
      );
    },
    width: '10%'
  },
];

export default EmployeesGridColumns;
