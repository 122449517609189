import React, { Component } from 'react';
import { Tabs, Row, Col } from 'antd';
import { Field, reduxForm, initialize, FieldArray, formValueSelector, change } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetEvent,
  UpdateEvent,
  CreateEvent,
} from '../../infra/requests/EventsRequests';
import {
  GetAreasList,
} from '../../infra/requests/AreasRequests';
import {
  GetInfo,
} from '../../infra/requests/EventInfoRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { 
  FormContainer, 
  BaseForm, 
  SpinLoading,  
  DefaultLanguageTab,
  SectionTitle
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import ImageInput from '../../components/generic/inputs/ImageInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import SelectTagsInput from '../../components/generic/inputs/SelectTagsInput';
import { EventTypes, EventSubTypes } from '../../infra/utils/EventTypes';
import SunDraftInput from '../../components/generic/inputs/SunDraftInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import NumberInput from '../../components/generic/inputs/NumberInput';
import TimeInput from '../../components/generic/inputs/TimeInput';
import SpeakersComponent from './components/SpeakersComponent';
import getEventDates from '../../infra/utils/EventDates';
import { GetSpeakersList } from '../../infra/requests/SpeakersRequests';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  type: 'required',
  subtype: 'required',
  name: 'required|languages',
  description: 'required|languages',
  date: 'required',
  start_time: 'required',
  location: 'required'
});

class ManageEventsPage extends Component {
  state = {
    isNew: false,
    loading: true,
    type: 0,
    subtypes: [],
    areas: [],
    speakers: [],
    eventInfo: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    const areas = await GetAreasList();
    const eventInfo = await GetInfo(process.env.REACT_APP_EVENT_INFO_ID);
    const { data } = await GetSpeakersList();

    CloseMenu();

    if(params.id) {
      const result = await GetEvent(params.id);
      dispatch(
        initialize('manage_events_form', {
          ...result.data,
        })
      );

      const subtypes = result?.data?.type ? EventSubTypes.filter(elem => elem.parent === parseInt(result?.data?.type)) : [];

      this.setState({ 
        loading: false,
        type: result?.data?.type,
        areas: areas?.data || [],
        speakers: data || [],
        subtypes,
        eventInfo: eventInfo?.data || undefined
      });
    } 
    else {
      dispatch(
        initialize('manage_events_form', {
          show_in_calendar: true
        })
      );

      this.setState({
        isNew: true,
        loading: false,
        areas: areas?.data || [],
        speakers: data || [],
        eventInfo: eventInfo?.data || undefined
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const { isNew } = this.state;

      const payload = FlattenToFormData(values);
      const request = isNew ? await CreateEvent(payload) : await UpdateEvent(params.id, payload);

      this.setState({ loading: false });
      
      if(request.success) return history.push('/events');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/events');
  };

  checkType = (value) => {
    const { dispatch } = this.props;

    const subtypes = value ? EventSubTypes.filter(elem => elem.parent === parseInt(value)) : [];

    //Clear Subtype field
    dispatch(change('manage_events_form', 'subtype', null));

    this.setState({
      type: parseInt(value),
      subtypes
    });
  }

  clearSpeakers = (value) => {
    const { dispatch } = this.props;

    //When selecting "Talk" we must clear the selected Speakers because we can only allow 1
    if(parseInt(value) === 2) {
      dispatch(change('manage_events_form', 'speakers', []));
    }
  }

  render() {
    const { handleSubmit, pristine, activeLanguage, languages, subtype } = this.props;
    const { isNew, loading, type, subtypes, areas, eventInfo, speakers } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Eventos', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer style={{ marginBottom: '50px' }}>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <SectionTitle>Informação Principal</SectionTitle>
            </Row>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={SelectInput}
                  name={'type'}
                  label={'Tipo *'}
                  placeholder={'Escolha o tipo'}
                  type='select'
                  data={EventTypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  execAfterChange={this.checkType}
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={SelectInput}
                  name={'subtype'}
                  label={'Subtipo *'}
                  placeholder={'Escolha o subtipo'}
                  type='select'
                  data={subtypes}
                  dataKey={'_id'}
                  dataLabel={'name'}
                  execAfterChange={this.clearSpeakers}
                  noteText='Ao selecionar "Talk", deverá selecionar novamente o Orador!'
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={24} lg={12}>
                <Field
                  component={SelectInput}
                  name={'date'}
                  label={'Data do Evento *'}
                  placeholder={'Selecione a data do evento'}
                  type='select'
                  data={getEventDates(eventInfo)}
                  dataKey={'_id'}
                  dataLabel={'date'}
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='location'
                  type='text'
                  label={'Localização *'}
                  placeholder={'Localização do evento'}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={TimeInput}
                  name='start_time'
                  label='Hora de Início *'
                  placeholder='Selecione a hora de início'
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TimeInput}
                  name='end_time'
                  label='Hora de Fim'
                  placeholder='Selecione a hora de fim (deixe em branco caso não seja aplicável)'
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={24} lg={12}>
                {
                  languages?.length > 1 &&
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                }
                <Field
                  component={TextInput}
                  name={`title.${activeLanguage?.code}`}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título do evento'}
                />
                <div style={{ marginTop: '10px' }}>
                  <Field
                    component={SunDraftInput}
                    name={`description.${activeLanguage?.code}`}
                    label={'Descrição *'}
                    placeholder={'Descrição do evento'}
                  />
                </div>
              </Col>
              {
                languages.filter((x) => !x.active).length > 0 &&
                <Col xs={24} lg={12}>
                  <Tabs>
                    {
                      languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`title.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título do evento'}
                          />
                          <div style={{ marginTop: '10px' }}>
                            <Field
                              component={SunDraftInput}
                              name={`description.${language.code}`}
                              type='text'
                              label={'Descrição *'}
                              placeholder={'Descrição do evento'}
                            />
                          </div>
                        </TabPane>
                      ))
                    }
                  </Tabs>
                </Col>
              }
            </Row>
            <Row gutter={24}>
              <SectionTitle>Áreas</SectionTitle>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  name='areas'
                  multiple={true}
                  component={SelectTagsInput}
                  options={areas}
                  translatable={true}
                  limit={2}
                />
              </Col>
            </Row>
            {
              type === 1 &&
              <React.Fragment>
                <Row gutter={24}>
                  <SectionTitle>Oradores</SectionTitle>
                </Row>
                <Row gutter={24}>
                  <Col xs={24} lg={12}>
                    <FieldArray 
                      component={SpeakersComponent} 
                      name='speakers'
                      subtype={subtype}
                      users={speakers}
                    />
                  </Col>
                  {parseInt(subtype) === 1 &&
                    <Col xs={24} lg={12}>
                      <Field
                        component={SelectInput}
                        name={'moderator'}
                        label={'Moderador'}
                        placeholder={'Escolha o Moderador'}
                        type='select'
                        data={speakers}
                        dataKey={'_id'}
                        dataLabel={'name'}
                      />
                    </Col>
                  }
                </Row>
              </React.Fragment>
            }
            
            <Row gutter={24}>
              <SectionTitle>Pontos</SectionTitle>
            </Row>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={NumberInput}
                  name='points'
                  label={'Pontos'}
                  placeholder={'Pontos'}
                  min={0}
                />
              </Col>
              {
                parseInt(subtype) === 3 &&
                <Col xs={24} lg={12}>
                  <Field
                    component={NumberInput}
                    name='points_winner'
                    label={'Pontos Vencedor'}
                    placeholder={'Pontos vencedor'}
                    min={0}
                  />
                </Col>
              }
            </Row>
            <Row gutter={24}>
              <SectionTitle>Badge</SectionTitle>
            </Row>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={ImageInput}
                  name='badge'
                  label='Badge'
                  ratio={1}
                  noCrop={true}
                />
              </Col>
              {
                parseInt(subtype) === 3 &&
                <Col xs={24} lg={12}>
                  <Field
                    component={ImageInput}
                    name='badge_winner'
                    label='Badge Vencedor'
                    ratio={1}
                    noCrop={true}
                  />
                </Col>
              }
            </Row>
            <Row gutter={24}>
              <SectionTitle>Outros</SectionTitle>
            </Row>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={NumberInput}
                  name='limit'
                  label={'Limite de Participantes'}
                  placeholder={'Limite de participantes'}
                  min={0}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '10px' }}>
              <Col xs={24} lg={12}>
                <Field
                  component={CheckboxInput}
                  name={`register_required`}
                  label={'Obrigatório Registo?'}
                  inlineCheckbox={true}
                />
              </Col>
            </Row>
            {
              type === 2 &&
              <React.Fragment>
                <Row gutter={24} style={{ marginTop: '10px' }}>
                  <Col xs={24} lg={12}>
                    <Field
                      component={CheckboxInput}
                      name={`show_in_calendar`}
                      label={'Mostrar na Agenda?'}
                      inlineCheckbox={true}
                    />
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginTop: '10px' }}>
                  <Col xs={24} lg={12}>
                    <Field
                      component={CheckboxInput}
                      name={`withdraw_points`}
                      label={'Retira Pontos?'}
                      inlineCheckbox={true}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            }
            <Row gutter={24} style={{ marginTop: '10px' }}>
              <Col xs={24} lg={12}>
                <Field
                  component={CheckboxInput}
                  name={`report_required`}
                  label={'Obrigatório para as bonificações?'}
                  inlineCheckbox={true}
                />
              </Col>
            </Row>

          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('manage_events_form');

ManageEventsPage = reduxForm({
  form: 'manage_events_form',
  validate: validations,
})(ManageEventsPage);

const mapStateToProps = state => ({
  subtype: selector(state, 'subtype')
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageEventsPage));