import React, { Component } from 'react';
import Table from '../../../components/generic/table/Table';
import {
  GetUserPointsHistory
} from '../../../infra/requests/UsersRequests';
import PointsHistoryFilters from './PointsHistoryFilters';
import { Separator } from '../../../styles/BasicStyles';
import { AddPoints, Points, PointsRow } from './Styles';
import moment from 'moment';
import { Button } from 'antd';

class PointsHistory extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    columns: [
      {
        title: 'Data',
        dataIndex: 'createdAt',
        render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
        width: '20%',
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        width: '60%',
      },
      {
        title: 'Pontos',
        key: 'points',
        render: (data) => this.renderPoints(data),
        width: '20%',
      }
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });

    try {
      const { user } = this.props;
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetUserPointsHistory(user._id, currentPage, pageSize, filters);

      this.setState({
        rows: result?.data?.items || [],
        total: result?.data?.total || 0,
        loading: false
      });

    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  renderPoints = (data) => {
    if(data.add_points) return data.points;
    return `- ${data.points}`;
  } 

  getQrCode = async (event, user) => {
    event.stopPropagation();
    window.open(`${process.env.REACT_APP_API}/users/${user._id}/qrcode`);
  };

  render() {
    const { user } = this.props;
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <PointsRow>
          <Points>Pontos: <span>{user.points || 0}</span></Points>
          <AddPoints>
            Adicionar pontos através da leitura do QrCode
            <Button key="back" onClick={e => this.getQrCode(e, user)} style={{marginLeft: 10}}>
              Abrir QrCode
            </Button>
          </AddPoints>
        </PointsRow>
        <Separator/>
        <PointsHistoryFilters queryChange={this.handleFilterChange}/>
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon='warning'
          emptyText={'Não existe Histórico inserido!'}
          total={total}
          rowKey={'_id'}
          hasPagination={true}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </React.Fragment>
    );
  }
}

export default PointsHistory;