export const PrimaryColour = '#816CFF';
export const SecondaryColour = '#171717';
export const ThirdColour = 'rgba(54, 43, 112, 0.6)';
export const PrimaryCTA = '#CACACA';
export const SecondaryCTA = '#A5A5A5';
export const AlertColour = '#ED1D24';
export const InputBorderColour = '#979797';
export const PrimaryBorderColor = '#F3F3F3';
export const SecondaryBorderColor = '#ED1D24';
export const MenuBorderColor = '#e8e8e8';
export const PrimaryBackgroundColor = '#ffffff';
export const SecondaryBackgroundColor = '#8c8c8c';
export const ThirdBackgroundColor = '#cccccc';
export const HeaderBackgroundColor = '#e8e8e8';