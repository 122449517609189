import React, { Component } from 'react';
import { Popconfirm, Icon, notification } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  GetAreas,
  DeleteArea,
  UpdateAreasOrder
} from '../../infra/requests/AreasRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import AreasFilters from './AreasFilters';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import OrderAreas from './OrderAreas';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';

class AreasPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        render: (value) => TranslateValue(value),
        width: '70%',
      },
      {
        title: 'Ações',
        render: (data) => (
          <TableButton error onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              placement='topRight'
              title='Tem a certeza que quer remover a área?'
              onConfirm={() => this.removeArea(data)}
            >
              <Icon style={{ marginRight: 10 }} type='delete' />
              {'Remover'}
            </Popconfirm>
          </TableButton>
        ),
      },
    ],
    rows: [],
    total: 0,
    orderModal: false
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/areas/${record.id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetAreas(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  removeArea = async (data) => {
    try {
      await DeleteArea(data.id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = (areas) => {
    const { dispatch } = this.props;
    dispatch(
      initialize(
        'manage_order_areas_form',
        { areas: [...areas] },
        false
      )
    );

    this.setState({ orderModal: true });
  };

  onSubmitOrder = async (values) => {
    try {
      this.setState({ loading: true });

      if(values.areas.length > 0) {
        let orderedList = [];
        Object.keys(values.areas).forEach(function (key) {
          let obj = { _id: values.areas[key]._id, order: key };
          orderedList.push(obj);
        });

        if(orderedList.length > 0) {
          const res = await UpdateAreasOrder({ order: orderedList });

          if(res.success) {
            notification.success({
              message: 'Registos alterados com sucesso!'
            });

            this.updateDataTable();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { history } = this.props;
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      orderModal
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Lista de Áreas</PageTitle>
            {`Áreas`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/areas/add')}
            />
            <BaseButton
              type='default'
              icon='retweet'
              text='Editar Ordem'
              onClick={() => this.openOrderModal(rows)}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <AreasFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Áreas inseridas!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
        <OrderAreas
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
      </React.Fragment>
    );
  }
}

export default connect()(AreasPage);