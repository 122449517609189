import moment from 'moment';

export const flattenObject = (obj, parent = undefined, res = {}) => {
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    const propName = parent ? parent + '.' + key : key;
    if (obj[key]) {
      if (typeof obj[key] == 'object') {
        if (obj[key].blob) {
          res[propName] = obj[key].blob;
        } else if (obj[key] instanceof moment) {
          res[propName] = moment.utc(obj[key]).format();
        } else flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    if (obj[key] === '') {
      res[propName] = '';
    }
    if (obj[key] === false) {
      res[propName] = false;
    }
  });
  return res;
};

export const FlattenToFormData = (object) => {
  const flatten = flattenObject(object);
  const formData = new FormData();
  Object.keys(flatten).forEach((key) => formData.append(key, flatten[key]));
  return formData;
};

const toFormData = (object, formData, parent) => {
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      if (Array.isArray(object[key])) {
        object[key].forEach((obj, index) => {
          toFormData(obj, formData, `${key}[${index}]`);
        });
      } else if (typeof object[key] === 'object') {
        if (object[key].blob) {
          formData.append(key, object[key].blob);
        } else {
          toFormData(object[key], formData, key);
        }
      } else {
        if (parent) {
          let index = `${parent}.${key}`;
          formData.append(index, object[key]);
        } else formData.append(key, object[key]);
      }
    }
  });
  return formData;
};

export const TransformToFormData = (object) =>
  toFormData(object, new FormData());
