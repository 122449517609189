import React from 'react';
import {
  InfoSectionTitle,
  InfoSectionText,
  InfoSectionContainer,
} from '../ConfigsStyles';
import PropTypes from 'prop-types';

const InfoSection = ({ title, text, isPrimaryTitle, children }) => (
  <InfoSectionContainer isPrimaryTitle={isPrimaryTitle}>
    <InfoSectionTitle isPrimaryTitle={isPrimaryTitle}>{title}</InfoSectionTitle>
    <InfoSectionText>{text}</InfoSectionText>
    {children}
  </InfoSectionContainer>
);

InfoSection.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default InfoSection;