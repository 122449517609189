import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InputDiv, InputLabelDiv } from "../inputs/InputStyles";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { ImageBox, InteractiveMapBox, InteractiveMapColumn, InteractiveMapLine, Overlay, StandAbsolute, TextStand } from "./MapStyles";
import MapModal from "./MapModal";
import { GetCompaniesList } from "../../../infra/requests/UsersRequests";
import ImageComponent from "../images/ImageComponent";
import HandleImageUrl from "../../../infra/services/images/HandleImageUrl";
import { HeaderButtonsContainer } from "../../../styles/BasicStyles";
import BaseButton from "../buttons/BaseButton";
import { CreateMapStand, DeleteMapStand, GetMap } from "../../../infra/requests/EventInfoRequests";

const size = 25

const InteractiveMapComponent = ({ label, rows, columns }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCells, setSelectedCells] = useState(undefined)
  const [openModal, setOpenModal] = useState(false)
  const [showDeleteBtn, setShowDeleteBtn] = useState(false)
  const [companies, setCompanies] = useState(undefined)
  const [map, setMap] = useState(undefined)
  const [table, setTable] = useState([])
  const [existCompanies, setExistCompanies] = useState([])
  let componentMounted = false;

  useEffect(() => {
    async function fetchData() {
      const { data } = await GetCompaniesList();
      setCompanies(data);
    }

    async function fetchDataMap() {
      const { data } = await GetMap();
      setMap(data);
    }
    if (!companies) fetchData();
    if (!map) fetchDataMap();
    
  }, [companies, componentMounted, map]);

  useEffect(() => {
    const exCompanies = []
    if(!map) return 
    map.forEach(m => {
      if (m.company && !exCompanies.find(c => c === m.company?._id))
      exCompanies.push(m.company._id)
    })

    setExistCompanies(exCompanies)
  }, [map]);

  Array.prototype.max = function() {
    return Math.max.apply(null, this);
  };
  
  Array.prototype.min = function() {
    return Math.min.apply(null, this);
  };
  
  useEffect(() => {
    let tableR = new Array();
    for (let i = 0; i < rows; i++) {
      const arr = new Array();
      for (let j = 0; j < columns; j++) {
        arr.push(undefined)
      }
      tableR.push(arr)
    }

    if (map?.length) {
      map.forEach(m => {
        const row = []
        const col = []
        const groupCells = m.cells.split(' | ')
        groupCells.forEach(c => {
          const cell = c.split(',')
          row.push(cell[0])
          col.push(cell[1])
          tableR[cell[0]][cell[1]] = m
        });
        const rmax = row.max()
        const rmin = row.min()
        const cmax = col.max()
        const cmin = col.min()
        const rowV = rmax - rmin + 1
        const colV = cmax - cmin + 1
        tableR[rmin][cmin] = {...tableR[rmin][cmin], size: {y: rowV, x: colV}}

      });
    }
    setTable(tableR)

  }, [map, columns, rows]);

  const onPressBlock = (x, y, val, isSelected) => {
    if (showDeleteBtn) {
      if (isSelected) {
        const newCells = selectedCells ? {...selectedCells} : {}
        delete newCells[`${x},${y}`]
        setSelectedCells(newCells);
        setShowDeleteBtn(false)
      }
      return;
    };

    if (table[x][y]) {
      setShowDeleteBtn(table[x][y])
    }

    const newCells = selectedCells ? {...selectedCells} : {}
    if (isSelected)
      delete newCells[`${x},${y}`]
    else
      newCells[`${x},${y}`] = true

    setSelectedCells(newCells);
  }

  const handleModalSave = async(type, value, name, color, textType, isEmpty) => {
    const isGroup = Object.keys(selectedCells).length > 1 ? true : false;

    const stand = {cells: Object.keys(selectedCells).join(' | '), type, isGroup, color, textType: textType || 'horizontal', isEmpty}
    if (type === 'company')
      stand.company = value
    else
      stand.name = name

    setOpenModal(false);
    try {
      setIsLoading(true);
      const { data } = await CreateMapStand(stand);

      if(data) {
        map.push(data)
        const newMap = [...map]
        setMap(newMap)
      }
      setSelectedCells(undefined);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }

  const deleteStand = async(cells) => {
    const keys = Object.keys(selectedCells)
    let stand = undefined
    keys.forEach(k => {
      const cell = k.split(',')
      if (table[cell[0]][cell[1]])
        stand = table[cell[0]][cell[1]]
    });

    if(stand){
      try {
        setIsLoading(true);
        const result = await DeleteMapStand(stand._id);

        if(result.success) {
          const newMap = map.filter(m => m._id !== stand._id)
          setMap(newMap)
        }
        setSelectedCells(undefined);
        setIsLoading(false);
        setShowDeleteBtn(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    }
  }

  //TODO: nao deixar submeter a mesma empresa
  return (
    <InputDiv>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      {/* <Row gutter={24} style={{ marginTop: '15px' }}>
        <Col xs={24} lg={12}>
          <Field
            component={TextInput}
            name='rows'
            type='number'
            label={'Linhas *'}
            placeholder={'Insira o número de linhas'}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Field
            component={TextInput}
            name='columns'
            type='number'
            label={'Colunas *'}
            placeholder={'Insira o número de colunas'}
          />
        </Col>
      </Row> */}
      <HeaderButtonsContainer buttons={2} style={{display: 'inline-flex'}}>
        <BaseButton
          type={'secondary'}
          icon={'delete'}
          text={'Eliminar stand'}
          onClick={() => deleteStand(selectedCells)}
          disabled={!showDeleteBtn || isLoading}
        />
        <BaseButton
          type={'primary'}
          icon={'plus'}
          text={'Criar novo stand'}
          onClick={() => setOpenModal(selectedCells)}
          disabled={!selectedCells || !Object.keys(selectedCells).length || showDeleteBtn || isLoading}
        />
      </HeaderButtonsContainer>
      <InteractiveMapBox>
        {table.map((row, i) => {
          return (
          <InteractiveMapLine>
            {row.map((col, j) => {
              const hasNextRow = col?._id && i < rows - 1 ? table[i + 1][j]?._id === col?._id : undefined
              const hasNextCol = col?._id && j < columns - 1 ? table[i][j + 1]?._id === col?._id : undefined
              const hasPrevCol = col?._id && j > 0 ? table[i][j - 1]?._id === col?._id : undefined
              const hasPrevRow = col?._id && i > 0 ? table[i - 1][j]?._id === col?._id : undefined
              const isSelected = selectedCells && selectedCells[`${i},${j}`]

              return (
              <InteractiveMapColumn onClick={() => onPressBlock(i, j, col, isSelected)} 
                color={col?.color || 'transparent'} 
                isSelected={!col?.size && isSelected}
                hasNextRow={hasNextRow} 
                hasNextCol={hasNextCol}
                hasPrevCol={hasPrevCol} 
                hasPrevRow={hasPrevRow}
                isEmpty={col?.isEmpty}
                size={size}
              >
                {col?.size && (
                  <StandAbsolute 
                    width={col?.size?.x}
                    height={col?.size?.y}
                    size={size}
                  >
                    {col?.company && (
                      <ImageBox borderColor={col.company.sponsor_tag === 1 ? '#B29170' : col.company.sponsor_tag === 2 ? '#9E9595' : '#A96B58'}>
                        <ImageComponent
                          color={col?.color}
                          url={HandleImageUrl(col?.company.image?.file)}
                        />
                        {isSelected && (
                          <Overlay />
                        )}
                      </ImageBox>
                    )}
                    {col.name && (
                      <>
                        {isSelected && (
                          <Overlay />
                        )}
                        <TextStand type={col.textType}>{col.name}</TextStand>
                      </>
                    )}
                  </StandAbsolute>
                )}
              </InteractiveMapColumn>
            )})}
          </InteractiveMapLine>
        )})}
      </InteractiveMapBox>
      <MapModal
        key={Math.random()}
        companies={companies?.filter(c => !existCompanies.find(a => a === c._id))}
        isOpen={openModal}
        onConfirm={handleModalSave}
        onClose={() => {
          setSelectedCells(undefined)
          setOpenModal(false)
        }}
      />
    </InputDiv>
  );
};

InteractiveMapComponent.propTypes = {
  label: PropTypes.string.isRequired
};


const selector = formValueSelector('manage_event_info_form');

const mapStateToProps = state => ({
  rows: selector(state, 'rows'),
  columns: selector(state, 'columns')
});

const mapActionToProps = () => {};

export default connect(mapStateToProps, mapActionToProps)(InteractiveMapComponent)