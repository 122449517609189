import React, { useEffect, useState } from "react";
import Table from "../../../components/generic/table/Table";
import { GetEventParticipants } from "../../../infra/requests/EventsRequests";

const columns = [
  {
    title: "Nome",
    dataIndex: "user",
    render: (v) => v?.name || "N/A",
  },
  {
    title: "Email",
    dataIndex: "user",
    render: (v) => v?.email || "N/A",
  },
  {
    title: "Estudante",
    dataIndex: "user",
    render: (v) => (v?.is_student ? "Sim" : "Não"),
  },
];

const EventParticipants = ({ event }) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const init = async () => {
      const result = await GetEventParticipants(event);
      if (result?.success) {
        setRows(result?.data);
      }
      setLoading(false);
    };
    init();
  }, [event]);

  return (
    <div>
      <div>Participantes</div>
      <div>
        <Table
          columns={columns}
          rows={rows}
          showHeader={true}
          loading={loading}
          emptyIcon="warning"
          emptyText={"Não existem participantes registados!"}
          rowKey={"_id"}
        />
      </div>
    </div>
  );
};

export default EventParticipants;

