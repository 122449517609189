import InitialState from "../InitialState";
import { SAVE_INFO } from "../ActionsType";

export default function(state = InitialState.info, action) {
  switch (action.type) {
    case SAVE_INFO:
      return { ...action.info, ready: true };
    default:
      return state;
  }
}
