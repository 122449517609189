import client from "../config/AxiosConfig";

export const GetEvent = async id => await client.get(`/events/${id}`);

export const GetEvents = async (currentPage, limit, filters) => await client.get(`/events/${currentPage}/${limit}?filter=${filters}`);

export const UpdateEvent = async (id, data) => await client.put(`/events/${id}`, data);

export const CreateEvent = async data => await client.post(`/events`, data);

export const DeleteEvent = async id => await client.delete(`/events/${id}`);

export const ToggleEventState = async (id, data) => await client.put(`/events/${id}/toggle`, data);

export const GetEventParticipants = async (id) => await client.get(`/events/${id}/participants`);
