import { Redirect, Route } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import { isEmpty } from "lodash";
import Layout from "../../components/generic/layout/Layout";

const AuthenticatedRoute = ({
  user,
  component: Component,
  props: cProps,
  ready,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout {...props} {...cProps}>
          {!ready ? (
            <div style={{ width: '100%', height: '100vh' }}>
              <Spin />
            </div>
          ) : !isEmpty(user) ? (
            <Component {...props} {...cProps} />
          ) : (
                <Redirect to={"/login"} />
              )}
        </Layout>
      )}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
  ready: state.info.ready
});

export default connect(mapStateToProps)(AuthenticatedRoute);
