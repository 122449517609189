import { TOGGLE_MENU, CLOSE_MENU } from '../ActionsType';

export default function(state = false, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return action.value;
    case CLOSE_MENU:
      return true;
    default:
      return state;
  }
}