import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, arraySplice, formValueSelector } from 'redux-form';
import { Modal, Button } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { OrderContainer, ChapterOrderName } from '../../styles/BasicStyles';

let OrderAreas = ({
  open,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  dispatch,
  areas = []
}) => {
  const onDragEnd = (data) => {
    if(data.destination && data.source.index !== data.destination.index) {
      const [removed] = areas.splice(data.source.index, 1);

      dispatch(
        arraySplice(
          'manage_order_areas_form',
          'areas',
          data.destination.index,
          0,
          removed
        )
      );
    }
  };

  return (
    <Modal
      visible={open}
      title="Editar ordem das Áreas"
      maskClosable={false}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          Gravar
        </Button>
      ]}>
      <OrderContainer>
        <DragDropContext onDragEnd={data => onDragEnd(data)}>
          <Droppable droppableId="areas">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}   
              >
                {areas.map((option, index) => (
                  <Draggable key={option._id} draggableId={option._id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ChapterOrderName>
                          {TranslateValue(option.name)}
                        </ChapterOrderName>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </OrderContainer>
    </Modal>
  );
};

const selector = formValueSelector('manage_order_areas_form');

OrderAreas = reduxForm({
  form: 'manage_order_areas_form'
})(OrderAreas);

const mapStateToProps = state => ({
  areas: selector(state, 'areas')
});

export default connect(mapStateToProps)(OrderAreas);