import React from 'react';
import { notification } from 'antd';
import { PillsSection, Pill, InputLabelDiv, InputDiv } from './InputStyles';
import TranslateValue from '../../../infra/services/translations/TranslateValue';

const SelectTagsInput = ({ label, input, meta, multiple, options, translatable, limit = 0 }) => {
  const onSelect = (value) => {
    if (multiple) {
      if (!Array.isArray(input.value)) {
        return input.onChange([value]);
      }
      const found = input.value.find((v) => v === value);
      if(found) {
        return input.onChange(input.value.filter((v) => value !== v));
      }
      else {
        if(limit > 0 && input.value.length >= limit) {
          return notification.error({
            message: 'Erro!',
            description: `Pode selecionar no máximo ${limit} áreas.`
          });
        }
        return input.onChange([...input.value, value]);
      }
    } else {
      return input.onChange(value);
    }
  };

  const isActive = (value) => {
    if (multiple) {
      if (!Array.isArray(input.value)) return false;
      return input.value.find((v) => v === value);
    } else return input.value === value;
  };

  return (
    <InputDiv first={false}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <PillsSection>
        {options.map((option) => (
          <Pill
            key={option._id}
            onClick={() => onSelect(option._id)}
            isActive={isActive(option._id)}
          >
            {translatable ? TranslateValue(option.name) : option.name}
          </Pill>
        ))}
      </PillsSection>
    </InputDiv>
  );
};

export default SelectTagsInput;