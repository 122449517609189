import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetSpeaker,
  CreateSpeaker,
  UpdateSpeaker
} from '../../infra/requests/SpeakersRequests';
import ImageInput from '../../components/generic/inputs/ImageInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';

const validations = FormValidator.make({
  name: 'required',
  image: 'required',
  company: 'required',
  job_title: 'required'
});

class ManageSpeakersPage extends Component {
  state = {
    isNew: false,
    loading: true 
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if(params.id) {
      const result = await GetSpeaker(params.id);
      dispatch(
        initialize('manage_speakers_form', {
          ...result.data,
        })
      );

      this.setState({ loading: false });
    } else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      if(values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateSpeaker(params.id, payload) : await CreateSpeaker(payload);

      if(success) return history.push('/speakers');
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/speakers');
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Oradores', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label={'Nome do Orador *'}
              placeholder={'Nome do orador'}
            />
            <Field
              component={TextInput}
              name='company'
              type='text'
              label={'Empresa *'}
              placeholder={'Empresa'}
            />
            <Field
              component={TextInput}
              name='job_title'
              type='text'
              label={'Cargo *'}
              placeholder={'Cargo'}
            />
            <Field
              component={ImageInput}
              name='image'
              label='Imagem *'
              ratio={1}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageSpeakersPage = reduxForm({
  form: 'manage_speakers_form',
  validate: validations,
})(ManageSpeakersPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageSpeakersPage));