import React from "react";
import { connect } from "react-redux";
import { 
  StyledHeader, 
  StyledLogoContainer,
  StyleHeaderLine,
  StyleUserContainer,
  StyleLogoImg,
  UserLogout
} from "./LayoutStyles";
import Logo from '../../../assets/icons/logo.png';
import { Icon } from 'antd';

const HeaderComponent = ({ user, logOut }) => {
  return (
    <StyledHeader className='no-print'>
      <StyledLogoContainer>
        <StyleLogoImg src={Logo}/>
      </StyledLogoContainer>
      <StyleUserContainer>
        <StyleHeaderLine>Bem vindo, { user.name }</StyleHeaderLine>
        <UserLogout title='Logout' onClick={logOut}>
          <Icon type={'logout'} />
        </UserLogout>
      </StyleUserContainer>
    </StyledHeader>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null)(HeaderComponent);