import styled from 'styled-components';
import { PrimaryColour, ThirdColour } from '../../../styles/Colours';

export const InteractiveMapBox = styled.div`
  display: inline-block;
  margin-top: 30px;
`;

export const InteractiveMapLine = styled.div`
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InteractiveMapColumn = styled.div`
  width: ${({width, size}) => width ? width * size : size || 25}px;
  height: ${({height, size}) => height ? height * size : size || 25}px;
  background-color: ${({isSelected, color}) => isSelected ? ThirdColour : color || 'transparent'};
  border-right: ${({hasNextCol}) => hasNextCol ? '0px' : '1px solid #b6b1b1'};
  border-top: ${({hasPrevRow}) => hasPrevRow ? '0px' : '1px solid #b6b1b1'};
  border-bottom: ${({hasNextRow}) => hasNextRow ? '0px' : '1px solid #b6b1b1'};
  border-left: ${({hasPrevCol}) => hasPrevCol ? '0px' : '1px solid #b6b1b1'};
  border-style: ${({isEmpty}) => isEmpty ? 'dashed' : 'solid'};
  position: relative;

  &:hover {
    background-color: ${({isSelected, color, theme}) => isSelected ? ThirdColour : color || theme.inputFocusColor};
  }
`;

export const StandAbsolute = styled.div`
  cursor: pointer;
  width: ${({width, size}) => width ? width * size : size || 25}px;
  height: ${({height, size}) => height ? height * size : size || 25}px;
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextStand = styled.div`
  color: white;
  transform: ${({type}) => type === 'vertical' ? 'rotate(90deg)' : 'rotate(0deg)'};
  white-space: nowrap;
`;

export const Overlay = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: ${ThirdColour};
  z-index: 1;
  top: 0;
`;

export const ImageBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: ${({borderColor}) => `3px solid ${borderColor}` || `3px solid ${PrimaryColour}`};
`;
