import React, { Component, Fragment } from "react";
import { Field, reduxForm, initialize } from "redux-form";
import TextInput from "../../components/generic/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/generic/buttons/BaseButton";
import queryString from "query-string";
import Logo from '../../assets/icons/logo.png';
import {
  LoginCard,
  LoginForm,
  LoginButtonDiv,
  ErrorSection,
  LoginContainer,
  StyledLogoContainer,
  StyleLogoImg,
} from "./AuthStyles";
import { Icon } from "antd";
import { ValidatePassword } from "../../infra/requests/AuthRequests";
const REACT_APP_LINK = process.env.REACT_APP_LINK;

const formRules = (values) => {
  const errors = FormValidator.make({
    email: 'required|email',
    password: 'required|min:6',
    confirm_password: "required|min:6",
  })(values)

  if (errors.email && errors.password && errors.confirm_password) {
    errors._error = 'Credenciais inválidas'
  } else if (errors.email) {
    errors._error = 'Email inválido'
  } else if (errors.password) {
    errors._error = 'Password inválida, verifique se tem no mínimo 6 caracteres.'
  } else if (errors.confirm_password) {
    errors._error = 'Password inválida, verifique se tem no mínimo 6 caracteres.'
  }

  if(values.password !== values.confirm_password) {
    errors._error = 'Passwords não coincidem.'
  }

  return errors
}

class LoginPage extends Component {
  state = {
    loading: false,
    success: false,
    bo: false,
  };

  componentDidMount() {
    const {
      location: { search },
      dispatch,
    } = this.props;
    const query = queryString.parse(encodeURI(search));
    
    dispatch(
      initialize("define_password", { email: query.email, id: query.id })
    );
    this.setState({ bo: query.role == "1" || false });
  }

  onSubmit = async (values) => {
    try {
      const { success } = await ValidatePassword(values);
      if (success) this.setState({ success: true });
    } catch (e) {
      console.error(e);
    }
  };

  renderContent() {
    const { 
      submitting, 
      history,
      submitFailed,
      invalid,
      error  
    } = this.props;
    const { success, bo } = this.state;
    if (success)
      return (
        <>
          <ErrorSection>
            Password alterada com sucesso 
          </ErrorSection>
          {bo ? (
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                htmlType="button"
                type="primary"
                text='Ir para o login'
                onClick={() => history.push("/login")}
              />
            </LoginButtonDiv>
          ) : (
            <LoginButtonDiv>
              <BaseButton
                variant="raised"
                type="primary"
                text='Ir para o login'
                onClick={() => window.open(
                  REACT_APP_LINK,
                  '_blank'
                )}
              />
            </LoginButtonDiv>
          )}
        </>
      );

    return (
      <Fragment>
        <Field
          component={TextInput}
          name="email"
          type="email"
          disabled
          label="Email"
          placeholder="Insira o seu email"
          prefix={<Icon type="mail" />}
        />
        <Field
          component={TextInput}
          name="password"
          type="password"
          label="Senha"
          placeholder="Insira a sua senha"
          prefix={<Icon type="lock" />}
        />
        <Field
          component={TextInput}
          name="confirm_password"
          type="password"
          label="Repetir Senha"
          placeholder="Insira a sua senha"
          prefix={<Icon type="lock" />}
        />
        {((submitFailed && invalid && error)) && (
          <div style={{color: 'red', paddingTop: 16}}>
            {error}
          </div>
        )}
        <LoginButtonDiv>
          <BaseButton
            variant="raised"
            htmlType="submit"
            type="primary"
            loading={submitting}
            text="Alterar"
          />
        </LoginButtonDiv>
      </Fragment>
    );
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <StyledLogoContainer>
              <StyleLogoImg src={Logo}/>
            </StyledLogoContainer>
            {this.renderContent()}
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

LoginPage = reduxForm({
  form: "define_password",
  validate: formRules,
})(LoginPage);

export default LoginPage;
