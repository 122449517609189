import React, { Component } from 'react';
import { Popconfirm, Icon, Modal, Button, notification } from 'antd';
import Table from '../../components/generic/table/Table';
import { Field, reduxForm, initialize } from "redux-form";
import FormValidator from "../../infra/services/validations/FormValidator";
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
  BaseForm,
} from '../../styles/BasicStyles';
import { 
  GetUsers, 
  DeleteUser, 
  ToggleUserState, 
  ActivateUser,
  SendDefinePassword,
  DownloadReport,
  DownloadStudentsReport,
  SendCertificate,
  SendUsersCertificates
} from '../../infra/requests/UsersRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import UsersFilters from './UsersFilters';
import { getUserType } from '../../infra/utils/UserTypes';
import moment from 'moment';
import { StateLabel } from './UserStyles';
import TextInput from '../../components/generic/inputs/TextInput';
import { auth_token_key } from '../../infra/config/LocalStorageKeys';

const formRules = (values) => {
  const errors = FormValidator.make({
    email: 'required|email',
    password: 'required|min:6'
  })(values)

  if (errors.email && errors.password) {
    errors._error = 'Credenciais inválidas'
  } else if (errors.email) {
    errors._error = 'Email inválido'
  } else if (errors.password) {
    errors._error = 'Password inválida, verifique se tem no mínimo 6 caracteres.'
  }

  return errors
}

class UsersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    open: false,
    columns: [
      {
        title: 'Tipo',
        render: (data) => {
          if (parseInt(data.type) != 3)
            return getUserType(data.type)
          
          return  `${getUserType(data.type)} - ${data.company?.name}`
        },
        width: '15%'
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '15%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '20%'
      },
      {
        title: 'Pontos',
        dataIndex: 'points',
        width: '8%'
      },
      {
        title: 'Estado',
        key: 'active',
        render: (data) => this.renderState(data),
        width: '10%'
      },
      {
        title: 'Válido',
        key: 'accepted',
        render: (data) => this.renderValid(data),
        width: '10%'
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            {
              parseInt(data.type) === 1 &&
              <TableButton onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title={
                    data.active
                      ? 'Quer desativar este utilizador?'
                      : 'Quer ativar este utilizador?'
                  }
                  onConfirm={() => this.toggleUser(data)}
                >
                  <Icon style={{ marginRight: 10 }} type={data.active ? 'close-circle' : 'check-circle'} />
                  {data.active ? ' Desativar' : ' Ativar'}
                </Popconfirm>
              </TableButton>
            }
            {
              parseInt(data.type) !== 1 && !data.accepted && 
              <TableButton onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  placement='topRight'
                  title={'Quer ativar este utilizador?'}
                  onConfirm={() => this.openModal(data)}
                >
                  <Icon style={{ marginRight: 10 }} type={'check-circle'} />
                  Ativar
                </Popconfirm>
              </TableButton>
            }
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o utilizador?'
                onConfirm={() => this.removeUser(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
            {parseInt(data.type) == 3 && !data.accepted && (
            <TableButton onClick={(e) => {e.stopPropagation(); this.sendDefinePassword(data)}}>
              <Icon style={{ marginRight: 10 }} type='mail' />
              {'Enviar definir password'}
            </TableButton>)}
            {parseInt(data.type) === 2 &&
              <TableButton onClick={(e) => {e.stopPropagation(); this.sendCertificate(data)}}>
                <Icon style={{ marginRight: 10 }} type={'mail'} />
                Enviar certificado
              </TableButton>
            }
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeUser = async (data) => {
    try {
      this.setState({ loading: true });
      await DeleteUser(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  toggleUser = async (record) => {
    try {
      this.setState({ loading: true });
      await ToggleUserState(record._id, { active: !record.active });
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  openModal = async (record) => {
    const {dispatch} = this.props
    try {
      this.setState({ open: true });
      dispatch(
        initialize("define_password", { email: record.email, id: record._id })
      );
    } catch (e) {
      console.error(e);
    }
  };

  activateUser = async (fields) => {
    try {
      this.setState({ loading: true });
      await ActivateUser(fields.id, {...fields});
      await this.updateDataTable();
      this.setState({ loading: false, open: false })
    } catch (e) {
      this.setState({ loading: false })
      console.error(e);
    }
  };

  sendDefinePassword = async (data) => {
    try {
      this.setState({ loading: true });
      await SendDefinePassword({user: data._id});
      this.setState({ loading: false})
      notification.success({
        message: "Email enviado"
      });
    } catch (e) {
      this.setState({ loading: false })
      notification.error({
        message: "Não foi possível enviar email"
      });
      console.error(e);
    }
  }
  
  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const result = await GetUsers(currentPage, pageSize, filters);
      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderState = (data) => {
    if(parseInt(data.type) !== 1) {
      const year = moment.utc().year();

      if(data.active_fo?.length > 0) {
        const state = data.active_fo.find(elem => elem.year === year);
        if(state) {
          return (
            <StateLabel active={state.active ? true : false}>{year} - {state.active ? 'Ativo' : 'Inativo'}</StateLabel>
          );
        }
      }
      return (
        <StateLabel active={false}>{year} - Inativo</StateLabel>
      );
    }
    return (
      <StateLabel active={data.active ? true : false}>{data.active ? 'Ativo' : 'Inativo'}</StateLabel>
    );
  };

  renderValid = (data) => {
    if(!data.accepted) {
      return (
        <StateLabel active={false}>Inativo</StateLabel>
      );
    }
    return (
      <StateLabel active={true}>Ativo</StateLabel>
    );
  };

  downloadReport = async () => {
    const authToken = localStorage.getItem(auth_token_key);

    try {
      this.setState({
        loadingExport: true
      });
      const result = await DownloadReport(authToken);
      console.log(result) 
      if(result) {
        const filename = `Participantes_Bonificados_${moment().format("DD-MM-YYYY")}.xlsx`;
        if(result.headers) {
          const contentDisposition = result.headers.get('content-disposition');
          if (!contentDisposition) {
            this.setState({
              loadingExport: false
            });
            // return ToasterService.error(translate("BO_ERROR_DOWNLOAD_REPORT"));
          }
        }

        //Create blob link to download
        const url = window.URL.createObjectURL(new Blob([result.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        //Append to html link element page
        document.body.appendChild(link);
        
        //Start download
        link.click();
        
        //Clean up and remove the link
        link.parentNode.removeChild(link);
      }
      this.setState({
        loadingExport: false
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loadingExport: false
      });
    }
  };


  downloadStudentsReport = async () => {
    const authToken = localStorage.getItem(auth_token_key);

    try {
      this.setState({
        loadingExport: true
      });
      const result = await DownloadStudentsReport(authToken);
      console.log(result) 
      if(result) {
        const filename = `Participantes_${moment().format("DD-MM-YYYY")}.xlsx`;
        if(result.headers) {
          const contentDisposition = result.headers.get('content-disposition');
          if (!contentDisposition) {
            this.setState({
              loadingExport: false
            });
            // return ToasterService.error(translate("BO_ERROR_DOWNLOAD_REPORT"));
          }
        }

        //Create blob link to download
        const url = window.URL.createObjectURL(new Blob([result.blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        //Append to html link element page
        document.body.appendChild(link);
        
        //Start download
        link.click();
        
        //Clean up and remove the link
        link.parentNode.removeChild(link);
      }
      this.setState({
        loadingExport: false
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loadingExport: false
      });
    }
  };

  sendCertificates = async () => {
    try {
      this.setState({
        loadingExport: true
      });
      const { success } = await SendUsersCertificates();

      if(success) {
        notification.success({
          message: "Emails enviados"
        });
      }
      this.setState({
        loadingExport: false
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Não foi possível enviar os emails"
      });
      this.setState({
        loadingExport: false
      });
    }
  };

  sendCertificate = async (data) => {
    try {
      this.setState({
        loadingExport: true
      });
      console.log(data)
      const { success } = await SendCertificate(data._id);

      if(success) {
        notification.success({
          message: "Email enviado"
        });
      }
      this.setState({
        loadingExport: false
      });
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Não foi possível enviar email"
      });
      this.setState({
        loadingExport: false
      });
    }
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, open } = this.state;
    const { 
      history, 
      handleSubmit,
      submitFailed,
      invalid,
      error 
    } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Utilizadores</PageTitle>
            {`Utilizadores`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={4} style={{display: 'inline-flex'}}>
            <BaseButton
              type={'default'}
              icon={'mail'}
              text={'Enviar Certificados'}
              onClick={this.sendCertificates}
            />
            <BaseButton
              type={'default'}
              icon={'download'}
              text={'Exportar Bonificados'}
              onClick={this.downloadReport}
            />
            <BaseButton
              type={'default'}
              icon={'download'}
              text={'Exportar Participantes'}
              onClick={this.downloadStudentsReport}
            />
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/users/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UsersFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Utilizadores inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
        <Modal
          visible={open}
          title="Ativar utilizador e definir password"
          maskClosable={false}
          onCancel={() => this.setState({open: false})}
          footer={[
            <Button key="back" onClick={() => this.setState({open: false})}>
              Cancelar
            </Button>,
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={handleSubmit(this.activateUser)}>
              Gravar
            </Button>
          ]}>
          <BaseForm onSubmit={handleSubmit(this.activateUser)}>
            <Field
              component={TextInput}
              name="email"
              type="email"
              disabled
              label="Email"
              placeholder="Insira o seu email"
              prefix={<Icon type="mail" />}
            />
            <Field
              component={TextInput}
              name="password"
              type="password"
              label="Senha"
              placeholder="Insira a sua senha"
              prefix={<Icon type="lock" />}
            />
            {((submitFailed && invalid && error)) && (
              <div style={{color: 'red', paddingTop: 16}}>
                {error}
              </div>
            )}
          </BaseForm>
        </Modal>
      </React.Fragment>
    );
  }
}

UsersPage = reduxForm({
  form: "define_password",
  validate: formRules,
})(UsersPage);

export default UsersPage;