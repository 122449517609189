import styled from "styled-components";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  float: ${(p) => (p.align ? p.align : "left")};
  opacity: ${(p) => (p.disabled ? 0.8 : 1)};
  
  ${p => p.type === 'secondary' ?
    (
      `color: #ffffff;
      background-color: rgba(0,0,0,0.65);
      border-color: rgba(0,0,0,0.65);`
    )
  : ''}

  &.ant-btn-danger {
    background-color: ${(p) => p.theme.primaryBackgroundColor};
    color: ${(p) => p.theme.inputErrorColor};

    &:hover {
      background-color: ${(p) => p.theme.inputErrorColor};
      color: white;
    }
  }
`;

export const FloatButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: ${({ position }) =>
    position
      ? position === 1
        ? "70px"
        : `${(position - 1) * 55 + 70}px`
      : "15px"};

  &.ant-btn-danger {
    background-color: ${(p) => p.theme.primaryBackgroundColor};
    color: ${(p) => p.theme.inputErrorColor};

    &:hover {
      background-color: ${(p) => p.theme.inputErrorColor};
      color: white;
    }
  }
`;