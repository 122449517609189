import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row } from 'antd';
import SelectInput from '../inputs/SelectInput';
import TextInput from '../inputs/TextInput';
import CheckboxInput from '../inputs/CheckboxInput';
import { SpinLoading } from '../../../styles/BasicStyles';

const types = [
  {
    _id: 'company',
    name: 'Empresa'
  },
  {
    _id: 'other',
    name: 'Outros'
  }
]

const text = [
  {
    _id: 'horizontal',
    name: 'Horizontal'
  },
  {
    _id: 'vertical',
    name: 'Vertical'
  }
]

let MapModal = ({
  isOpen,
  onClose,
  onConfirm,
  companies
}) => {
  const [typeValue, setTypeValue] = useState(undefined)
  const [value, setValue] = useState(undefined)
  const [color, setColor] = useState(undefined)
  const [name, setName] = useState(undefined)
  const [textType, setTextType] = useState(undefined)
  const [isEmpty, setIsEmpty] = useState(false)

  const cells = Object.keys(isOpen)
  return (
    <Modal
      title='Adicionar Stand'
      visible={isOpen}
      onOk={() => onConfirm(typeValue, value, name, color, textType, isEmpty)}
      onCancel={onClose}
      maskClosable={false}
    >
      <Row gutter={24}>
        <Col xs={24}>
          Células selecionadas: {cells.join(' | ')}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={12} lg={12}>
          <SelectInput
            label='Tipo'
            allowClear
            placeholder='Escolher tipo de stand'
            data={types}
            dataKey='_id'
            dataLabel='name'
            defaultValue={typeValue}
            input={{
              value: typeValue,
              onChange: (v) => setTypeValue(v)
            }}
            meta={{valid: true}}
          />
        </Col>
        {!!typeValue && typeValue !== 'company' && (
          <Col xs={24} md={12} lg={12}>
            <CheckboxInput
              name='isEmpty'
              label='Stand vazio?'
              input={{
                value: isEmpty,
                onChange: (v) => setIsEmpty(!isEmpty)
              }}
              meta={{valid: true}}
            />
          </Col>
        )}
      </Row>
      {!!typeValue && (
        <>
          <Row gutter={24}>
            <Col xs={24} md={12} lg={12}>
              {typeValue === 'company' ? (
                <SelectInput
                  label='Empresa'
                  allowClear
                  placeholder='Escolher empresa'
                  data={companies}
                  dataKey='_id'
                  dataLabel='name'
                  defaultValue={value}
                  input={{
                    value: value,
                    onChange: (v) => setValue(v)
                  }}
                  meta={{valid: true}}
                />
              ) : !isEmpty ? (
                <TextInput
                  label='Nome'
                  allowClear
                  placeholder='Escreva um nome para o stand'
                  type='text'
                  input={{
                    value: name,
                    onChange: (event) => setName(event.target.value)
                  }}
                  meta={{valid: true}}
                />
              ) : null}
            </Col>
            {!isEmpty && (
              <Col xs={24} md={12} lg={12}>
                <TextInput
                  label='Cor'
                  allowClear
                  placeholder='Escreva uma cor'
                  type='text'
                  input={{
                    value: color,
                    onChange: (event) => setColor(event.target.value)
                  }}
                  meta={{valid: true}}
                />
              </Col>
            )}
          </Row>
          {typeValue !== 'company' && !isEmpty && (
            <Row gutter={24}>
              <Col xs={24} md={12} lg={12}>
                <SelectInput
                  label='Disposição do Texto'
                  placeholder='Escolher disposição do texto'
                  data={text}
                  dataKey='_id'
                  dataLabel='name'
                  defaultValue={textType}
                  input={{
                    value: textType,
                    onChange: (v) => setTextType(v)
                  }}
                  meta={{valid: true}}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </Modal>
  );
};

MapModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default MapModal
