import client from '../config/AxiosConfig';

export const GetInfo = async id => await client.get(`/event_info/${id}`);

export const UpdateInfo = async (id, fields)=> {
    const formData = new FormData()
    formData.append('event_available', fields.event_available)

    if (fields.date_1)
      formData.append('dates[0]', fields.date_1)
    if (fields.date_2)
      formData.append('dates[1]', fields.date_2)

    if (fields.image && typeof fields.image === 'object' && fields.image.blob) {
      formData.append('image', fields.image.blob);
    }

    return client.put(`/event_info/${id}`, formData)
  };

export const GetMap = async () => await client.get('/map');

export const UpdateMapStand = async (id, data) => await client.put(`/map/${id}`, data);

export const CreateMapStand = async data => await client.post('/map', data);

export const DeleteMapStand = async id => await client.delete(`/map/${id}`);