import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import { 
  GetEvents, 
} from '../../infra/requests/EventsRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { LeaderBoardContainer, LeaderBoardHeader, LeaderBoardTitle, LeaderBoardContent, TableFirstColumn, TableMiddleColumn, TableLastColumn, TableHeader, TableLine, ImageRanking, Number, ImageRankingEmpty, UserImage, UserName, UserPoints } from './LeaderBoardStyles';
import { GetLeaderBoard } from '../../infra/requests/BaseRequests';
const { REACT_APP_IMAGES_URL } = process.env;

class LeaderBoardPage extends Component {
  state = {
    ranking: undefined,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    try {
      const result = await GetLeaderBoard();

      this.setState({
        ranking: result.data.list
      });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.updateDataTable, 60000);
    } catch (e) {
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };


  GetImageURL = (image) => {
    const photoURL =
      image && image.file ? REACT_APP_IMAGES_URL + image.file : undefined
    return photoURL
  };

  render() {
    const { ranking } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <LeaderBoardContainer>
        <LeaderBoardHeader>
          <LeaderBoardTitle>Ranking</LeaderBoardTitle>
        </LeaderBoardHeader>
        <LeaderBoardContent>
          <TableHeader>
            <TableFirstColumn>Ranking</TableFirstColumn>
            <TableMiddleColumn>Participante</TableMiddleColumn>
            <TableLastColumn>Pontos</TableLastColumn>
          </TableHeader>
          {ranking && ranking.map((user, i) => (<TableLine key={i}>
            <TableFirstColumn>
              {i < 3 ? (<ImageRanking src={
                i === 0 ? require('../../assets/icons/first.png')
                : i === 1 ? require('../../assets/icons/second.png')
                : i === 2 ? require('../../assets/icons/third.png')
                : null
              }/>) : (
                <ImageRankingEmpty />
              )}
              <Number>{i + 1}</Number>
            </TableFirstColumn>
            <TableMiddleColumn>
              <UserImage src={
                this.GetImageURL(user.image) ||
                require('../../assets/icons/default_user.png')
              }/>
              <UserName>{user.name}</UserName>
            </TableMiddleColumn>
            <TableLastColumn>
              <UserPoints>{user.points} pt</UserPoints>
            </TableLastColumn>
          </TableLine>))}
        </LeaderBoardContent>
        </LeaderBoardContainer>
      </React.Fragment>
    );
  }
}

export default LeaderBoardPage;