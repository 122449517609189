import client from "../config/AxiosConfig";

export const Login = async data => await client.post("/auth/login", data);

export const RecoverAccount = async data => await client.post("/auth/recover", data);

export const ResetPassword = async data => await client.post("/auth/recover-confirmation", data);

export const RegisterConfirmation = async data => await client.post("/auth/register", data);

export const ValidateAccount = async data => await client.post("/auth/fo/validate", data);

export const ValidatePassword = async (data) =>
  client.post("/auth/define-password", data);