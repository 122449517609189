import React, { Component } from 'react';
import {
  GetUserBadges
} from '../../../infra/requests/UsersRequests';
import { 
  Separator, 
  SpinLoading, 
  SectionTitle 
} from '../../../styles/BasicStyles';
import {
  BadgesRow,
  BadgeColumn,
  BadgeImgContainer,
  BadgeImg,
  BadgeTitle,
  BadgePoints,
  BadgePointsWinner
} from './Styles';
import { Points } from './Styles';
import Logo from '../../../assets/icons/logo.png';
const { REACT_APP_IMAGES_URL } = process.env;

class BadgesHistory extends Component {
  state = {
    loading: true,
    data: {}
  };

  componentDidMount = async () => {
    this.setState({ loading: true });

    try {
      const { user } = this.props;

      const result = await GetUserBadges(user._id);

      this.setState({
        data: result?.data || {},
        loading: false
      });

    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { data, loading } = this.state;

    if(loading) return <SpinLoading/>;

    return (
      <React.Fragment>
        <Points>Ganhou <span>{data?.totalWon || 0}</span> badges de um total de <span>{data?.totalBadges || 0}</span></Points>
        <Separator/>
        {
          data?.actions?.length > 0 &&
          <React.Fragment>
            <SectionTitle>Action Badges</SectionTitle>
            <BadgesRow>
              {
                data.actions.map((badge, index) => {
                  const photoURL =
                  badge && badge.badge && badge.badge.file ? REACT_APP_IMAGES_URL + badge.badge.file : Logo
            
                  return (
                    <BadgeColumn key={index}>
                      <BadgeImgContainer>
                        <BadgeImg won={badge.won} src={photoURL}/>
                      </BadgeImgContainer>
                      <BadgeTitle>{badge.title.pt}</BadgeTitle>
                      <BadgePoints>{badge.points > 0 ? badge.points : '-'} pts</BadgePoints>
                    </BadgeColumn>
                  )
                })
              }
            </BadgesRow>
          </React.Fragment>
        }
        {
          data?.companies?.length > 0 &&
          <React.Fragment>
            <SectionTitle>Company Badges</SectionTitle>
            <BadgesRow>
              {
                data.companies.map((badge, index) => {
                  const photoURL =
                  badge && badge.image && badge.image.file ? REACT_APP_IMAGES_URL + badge.image.file : Logo
                  return (
                    <BadgeColumn key={index}>
                      <BadgeImgContainer>  
                        <BadgeImg won={badge.won} src={photoURL}/>
                      </BadgeImgContainer>
                      <BadgeTitle>{badge.name}</BadgeTitle>
                      <BadgePoints>{badge.points > 0 ? badge.points : '-'} pts</BadgePoints>
                    </BadgeColumn>
                  )
                })
              }
            </BadgesRow>
          </React.Fragment>
        }
        {
          data?.talks?.length > 0 &&
          <React.Fragment>
            <SectionTitle>Talk Badges</SectionTitle>
            <BadgesRow>
              {
                data.talks.map((badge, index) => {
                  const photoURL =
                  badge && badge.badge && badge.badge.file ? REACT_APP_IMAGES_URL + badge.badge.file : Logo
                  return (
                    <BadgeColumn key={index}>
                      <BadgeImgContainer>
                        <BadgeImg won={badge.won} src={photoURL}/>
                      </BadgeImgContainer>
                      <BadgeTitle>{badge.title.pt}</BadgeTitle>
                      <BadgePoints>{badge.points > 0 ? badge.points : '-'} pts</BadgePoints>
                    </BadgeColumn>
                  )
                })
              }
            </BadgesRow>
          </React.Fragment>
        }
        {
          data?.activities?.length > 0 &&
          <React.Fragment>
            <SectionTitle>Activity Badges</SectionTitle>
            <BadgesRow>
              {
                data.activities.map((badge, index) => {
                  const photoURL =
                  badge && badge.badge && badge.badge.file ? REACT_APP_IMAGES_URL + badge.badge.file : Logo
                  const photoWinnerURL =
                  badge && badge.badge_winner && badge.badge_winner.file ? REACT_APP_IMAGES_URL + badge.badge_winner.file : Logo
                  return (
                    <BadgeColumn key={index}>
                      <BadgeImgContainer>
                        {
                          badge.activity_winner ?
                          <BadgeImg won={true} src={photoWinnerURL}/>
                          :
                          <BadgeImg won={badge.won} src={photoURL}/>
                        }
                      </BadgeImgContainer>
                      <BadgeTitle>{badge.title.pt}</BadgeTitle>
                      <BadgePoints>{badge.points > 0 ? badge.points : '-'} pts</BadgePoints>
                      { badge.points_winner > 0 && badge.activity_winner && <BadgePointsWinner>{badge.points_winner > 0 ? badge.points_winner : '-'} pts <span>(vencedor)</span></BadgePointsWinner> }
                    </BadgeColumn>
                  )
                })
              }
            </BadgesRow>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

export default BadgesHistory;