import React from 'react';
import PropTypes from 'prop-types';
import { Icon, notification, Popconfirm } from 'antd';
import Dropzone from 'react-dropzone';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import { 
  FileSection,
  InputLabelDiv, 
  ResponsePill, 
  ExtraPill,
  InputDiv
} from './InputStyles';

const { REACT_APP_IMAGES_URL } = process.env;

const FilesInput = ({ label, input, accept }) => {
  const openFile = (id) =>
    window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');

  const buildImageObject = (blob) => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob,
  });

  const handleDrop = (accepted, rejected) => {
    if(rejected && rejected.length > 0) {
      if(!accept.includes(rejected[0].type)) {
        return notification.error({
          message: 'Ficheiro Inválido',
          description: 'O ficheiro tem um formato inválido.',
        });
      }
      else if(rejected[0].size > 12000000) {
        return notification.error({
          message: 'Ficheiro Inválido',
          description: 'O ficheiro tem um tamanho superior a 12MB.',
        });
      }
    }
    else if(accepted.length <= 0) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido.',
      });
    }

    const uploaded = buildImageObject(accepted[0]);
    input.onChange(uploaded);
  };

  const deleteFile = () => {
    input.onChange(null);
  }

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept={accept}
        maxSize={12000000}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <FileSection {...getRootProps()}>
              <input {...getInputProps()} />
              <Icon type='file'/>&nbsp;Clique ou arraste um ficheiro
            </FileSection>
          );
        }}
      </Dropzone>
    );
  };

  if(input.value) {
    const saved = input.value._id;
    const filename = TranslateValue(
      saved ? input.value.filename : input.value.blob.name
    );
    const size = (
      (saved ? input.value.length : input.value.size) /
      (1024 * 1024)
    ).toFixed(2);

    return (
      <InputDiv first={false}>
        { label && <InputLabelDiv>{label}</InputLabelDiv> }
        <div style={{ marginTop: '5px', marginBottom: '10px' }}>
          <ResponsePill single={false}>{filename}</ResponsePill>
          <ExtraPill>{size}MB</ExtraPill>
          {
            saved &&
            <React.Fragment>
              <ExtraPill link onClick={() => openFile(input.value._id)}>
                Abrir ficheiro
              </ExtraPill>
            </React.Fragment>
          }
          <Popconfirm
            placement='topRight'
            title='Tem a certeza que quer apagar o ficheiro?'
            onConfirm={() => deleteFile()}
          >
            <ExtraPill last link remove>
              Apagar
            </ExtraPill>
          </Popconfirm>
        </div>
      </InputDiv>
    );
  }

  return (
    <InputDiv first={false}>
      { label && <InputLabelDiv>{label}</InputLabelDiv> }
      {renderDropZone()}
    </InputDiv>
  )
};

FilesInput.propTypes = {
  input: PropTypes.object.isRequired,
  accept: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default FilesInput;