export const EventTypes = [
  { _id: 1, name: 'Evento', bgColor: '#2196F3', fontColor: '#ffffff' },
  { _id: 2, name: 'Atividade', bgColor: '#4CAF50', fontColor: '#ffffff' }
];

export const getEventType = (value) => {
  if(Number.isInteger(value) && value > 0 && EventTypes[value - 1]) return EventTypes[value - 1];
  return null;
};

export const EventSubTypes = [
  { _id: 1, name: 'Conferência', parent: 1, bgColor: '#FFC107', fontColor: '#ffffff' },
  { _id: 2, name: 'Talk', parent: 1, bgColor: '#795548', fontColor: '#ffffff' },
  { _id: 3, name: 'Desafio', parent: 2, bgColor: '#9C27B0', fontColor: '#ffffff' },
  { _id: 4, name: 'Atração', parent: 2, bgColor: '#607D8B', fontColor: '#ffffff' }
];

export const getEventSubType = (value) => {
  if(Number.isInteger(value) && value > 0 && EventSubTypes[value - 1]) return EventSubTypes[value - 1];
  return null;
};