import React, { Fragment } from "react";
import ApplicationRoutes from "./routes/routes";
import { BasicStyles } from "./styles/BasicStyles";

const App = () => {
  return (
    <Fragment>
      <ApplicationRoutes />
      <BasicStyles />
    </Fragment>
  );
};

export default App;
