import { Route } from "react-router-dom";
import React from "react";
import { Spin } from "antd";
import { connect } from "react-redux";

const UnauthenticatedRoute = ({
  component: Component,
  props: cProps,
  ready,
  ...rest
}) => {
  return !ready ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        textAlign: "center",
        lineHeight: "100vh"
      }}
    >
      <Spin size={"large"} />
    </div>
  ) : (
    <Route {...rest} render={props => <Component {...props} {...cProps} />} />
  );
};

const mapStateToProps = state => ({
  ready: state.info.ready
});

export default connect(mapStateToProps)(UnauthenticatedRoute);
