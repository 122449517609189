export default [
  {
    type: 'menu',
    name: 'Dashboard',
    to: '/',
    icon: 'home',
    exact: true
  },
  {
    type: 'menu',
    name: 'Informação do Evento',
    to: '/event-info',
    icon: 'picture',
    exact: true
  },
  {
    type: 'menu',
    name: 'Eventos',
    to: '/events',
    icon: 'calendar',
    exact: false
  },
  {
    type: 'menu',
    name: 'Oradores',
    to: '/speakers',
    icon: 'notification',
    exact: false
  },
  {
    type: 'menu',
    name: 'Áreas',
    to: '/areas',
    icon: 'folder',
    exact: false
  }, 
  {
    type: 'menu',
    name: 'Prémios',
    to: '/prizes',
    icon: 'trophy',
    exact: false
  },
  {
    type: 'menu',
    name: 'Pontos',
    to: '/points',
    icon: 'points.svg',
    external_icon: true,
    exact: false
  },
  {
    type: 'menu',
    name: 'Páginas Dinâmicas',
    to: '/dynamic-pages',
    icon: 'copy',
    exact: false,
  },
  {
    type: 'menu',
    name: 'Informações Gerais',
    to: '/configs',
    icon: 'read',
    exact: false
  },  
  {
    type: 'menu',
    name: 'Templates de Email',
    to: '/email-templates',
    icon: 'mail',
    exact: false
  },

  {
    type: 'menu',
    name: 'Traduções',
    to: '/translations',
    icon: 'global',
    exact: true
  },
  {
    type: 'menu',
    name: 'Empresas',
    to: '/companies',
    icon: 'shop',
    exact: false
  },
  {
    type: 'menu',
    name: 'Utilizadores',
    to: '/users',
    icon: 'team',
    exact: false
  },
  {
    type: 'menu',
    name: 'Perfil',
    to: '/my-profile',
    icon: 'user',
    exact: false
  }
];