import client from "../config/AxiosConfig";

export const GetUser = async id => await client.get(`/users/${id}`);

export const GetUsers = async (currentPage, limit, filters) => await client.get(`/users/${currentPage}/${limit}?filter=${filters}`);

export const UpdateUser = async (id, data) => await client.put(`/users/${id}`, data);

export const CreateUser = async data => await client.post(`/users`, data);

export const DeleteUser = async id => await client.delete(`/users/${id}`);

export const GetAdminList = async () => await client.get(`/users/admins`);

export const GetAgentsList = async () => await client.get(`/users/agents`);

export const ToggleUserState = async (id, data) => await client.put(`/users/${id}/toggle`, data);

export const UpdateProfile = async (id, data) => await client.put(`/users/profile/${id}`, data); 

export const GetProfile = async () => await client.get('/users/my-profile');

export const GetUserPointsHistory = async (id, currentPage, limit, filters) => await client.get(`/user_points/${id}/${currentPage}/${limit}?filter=${filters}`);

export const GetUserBadges = async id => await client.get(`/users/badges/${id}`);

export const ActivateUser = async (id, data) => await client.put(`/users/${id}/define-password`, data);

export const SendDefinePassword = async (data) => await client.post('/users/send_define_password', data);

export const DownloadReport = (authToken) => { 
  return new Promise((accept, reject) => {
    fetch(`${process.env.REACT_APP_API}/users/report`, { 
      method: 'POST',
      bosy: '',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` } 
    })
    .then(response => {
      return response.blob().then(blob => {
        return {
          headers: response.headers,
          blob
        }
      })
    })
    .then(data => accept({ headers: data.headers, blob: data.blob }))
    .catch(error => reject(error))
  });
}

export const DownloadStudentsReport = (authToken) => { 
  return new Promise((accept, reject) => {
    fetch(`${process.env.REACT_APP_API}/users/studentsReport`, { 
      method: 'POST',
      bosy: '',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authToken}` } 
    })
    .then(response => {
      return response.blob().then(blob => {
        return {
          headers: response.headers,
          blob
        }
      })
    })
    .then(data => accept({ headers: data.headers, blob: data.blob }))
    .catch(error => reject(error))
  });
}

export const GetCompaniesList = async () => await client.get('/companies');

export const SendUsersCertificates = async () =>
  await client.get('/users/certificates/email');

export const SendCertificate = async (id) =>
  await client.get(`/users/certificate/email/${id}`);
