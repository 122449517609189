import FR from './france_flag.svg';
import PT from './portugal_flag.svg';
import DE from './germany_flag.png';

export const GetAvailableLanguages = () => ['pt', 'fr', 'de'];

export const Languages = [
  { code: 'pt', name: 'Português', icon: PT },
  { code: 'fr', name: 'Francês', icon: FR },
  { code: 'de', name: 'Alemão', icon: DE }
];

export const GetTranslationDescription = (code) =>
  Languages.find((x) => x.code === code)?.name;

export const TranslateFromObject = (object, code) => {
  if (!object) return '';
  if (object[code]) return object[code];
  if (object['pt']) return object['pt'];
  if (Object.keys(object).length) return object[Object.keys(object)[0]];
  return object;
};
