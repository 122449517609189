import React, { Component } from 'react';
import { Tabs, Row, Col } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetArea,
  CreateArea,
  UpdateArea
} from '../../infra/requests/AreasRequests';
import ImageInput from '../../components/generic/inputs/ImageInput';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  name: 'required|languages',
  image: 'required',
});

class ManageAreasPage extends Component {
  state = {
    isNew: false,
    loading: true 
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if(params.id) {
      const result = await GetArea(params.id);
      dispatch(
        initialize('manage_areas_form', {
          ...result.data,
        })
      );

      this.setState({ loading: false });
    } else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      if(values.image && !values.image.blob) {
        delete values.image;
      }

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateArea(params.id, payload) : await CreateArea(payload);

      if(success) return history.push('/areas');
      return this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/areas');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';
    
    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Áreas', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextInput}
                  name={`name.${activeLanguage?.code}`}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título da Área'}
                />
              </Col>
              {
                languages.filter((x) => !x.active).length > 0 &&
                <Col xs={12}>
                  <Tabs>
                    {
                      languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={TextInput}
                            name={`name.${language.code}`}
                            type='text'
                            label={'Título *'}
                            placeholder={'Título da Área'}
                          />
                        </TabPane>
                      ))
                    }
                  </Tabs>
                </Col>
              }
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={12}>
                <Field
                  component={ImageInput}
                  name='image'
                  label='Imagem *'
                  ratio={1}
                  noCrop={true}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageAreasPage = reduxForm({
  form: 'manage_areas_form',
  validate: validations,
})(ManageAreasPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageAreasPage));