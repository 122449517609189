import styled from 'styled-components';
import { device } from '../../../styles/Responsive';

export const Points = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;

  & span {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const BadgesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const BadgeColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 16.666%;
  margin-bottom: 20px;
  padding: 0px 3px;

  @media ${device.desktopL} {
    flex-basis: 20%;
  }

  @media ${device.laptopL} {
    flex-basis: 25%;
  }

  @media ${device.laptopS} {
    flex-basis: 33.333%;
  }

  @media ${device.tabletS} {
    flex-basis: 50%;
  }

  @media ${device.mobileL} {
    flex-basis: 100%;
  }
`;

export const BadgeImgContainer = styled.div`
  width: 80px;
  height: 80px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const BadgeImg = styled.img`
  width: 100%;
  max-width: 80px;
  max-height: 80px;

  ${({ won }) => won ?
    ``
    :
    `filter: grayscale(100%);
    opacity: 0.4;`
  };
`;

export const BadgeTitle = styled.div`
  margin-bottom: 5px;
  text-align: center;
`;

export const BadgePoints = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const BadgePointsWinner = styled.div`
  background: #F1F2F2;
  border-radius: 4px;
  padding: 2px 5px;
  font-weight: bold;

  & span {
    font-weight: normal;
  }
`;

export const PointsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
`;

export const AddPoints = styled.div`
  margin-right: 0px;
`;