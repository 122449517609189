import client from '../config/AxiosConfig';

export const GetArea = async id => await client.get(`/areas/${id}`);

export const GetAreas = async (currentPage, limit, filters) => await client.get(`/areas/${currentPage}/${limit}?filter=${filters}`);

export const GetAreasList = async () => await client.get(`/areas`);

export const CreateArea = async (data) => await client.post(`/areas`, data);

export const UpdateArea = async (id, data) => await client.put(`/areas/${id}`, data);

export const DeleteArea = async (id) => await client.delete(`/areas/${id}`);

export const UpdateAreasOrder = async (data) => await client.post(`/areas/order`, data);