import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetDynamicPage,
  UpdateDynamicPage,
  CreateDynamicPage,
} from '../../infra/requests/BaseRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab,
} from '../../styles/BasicStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import FormValidator from '../../infra/services/validations/FormValidator';
import SunDraftInput from '../../components/generic/inputs/SunDraftInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
const TabPane = Tabs.TabPane;

const validations = FormValidator.make({
  description: 'required|languages'
});

class ManageDynamicPages extends Component {
  state = {
    isNew: false,
    loading: true,
    ready: false,
    info: {}
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;

    CloseMenu();

    if(params.id !== 'add') {
      const result = await GetDynamicPage(params.id);
      dispatch(initialize('manage_dynamic_page_form', { ...result.data }));

      this.setState({
        ready: true,
        isNew: false,
        info: result || {},
      });
    } 
    else {
      this.setState({
        ready: true,
        isNew: true,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
      } = this.props;
      const { isNew } = this.state;

      isNew ? await CreateDynamicPage(values) : await UpdateDynamicPage(params.id, values);
      return history.push('/dynamic-pages');
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    try {
      const { history } = this.props;
      return history.push('/dynamic-pages');
    } 
    catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { isNew, ready } = this.state;

    if(!ready) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Páginas Dinâmicas', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={12}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={SunDraftInput}
                  name={`description.${activeLanguage?.code}`}
                  label={'Descrição'}
                />
              </Col>
              {
                languages.filter((x) => !x.active).length > 0 &&
                <Col xs={12}>
                  <Tabs>
                    {languages
                      .filter((x) => !x.active)
                      .map((language) => (
                        <TabPane
                          tab={GetTranslationDescription(language.code)}
                          key={language.code}
                        >
                          <Field
                            component={SunDraftInput}
                            name={`description.${language.code}`}
                            label={'Descrição'}
                          />
                        </TabPane>
                      ))}
                  </Tabs>
                </Col>
              }
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageDynamicPages = reduxForm({
  form: 'manage_dynamic_page_form',
  validate: validations,
})(ManageDynamicPages);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManageDynamicPages));