import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { InputLabelDiv, SunDraftWrapper } from './InputStyles';
import sanitizeHtml from 'sanitize-html';

const emptyDraft = /^<p>(<br>|<br\/>)*<\/p>/;

const sanitizeOptions = {
  allowedTags: [
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'strong',
    'del',
    'em',
    'u',
    'sub',
    'sup',
    'ul',
    'li',
    'ol',
    'span',
    'a',
    'hr',
    'img',
    'div',
    'figure'
  ],
  allowedAttributes: {
    p: ['style'],
    h1: ['style'],
    h2: ['style'],
    h3: ['style'],
    h4: ['style'],
    h5: ['style'],
    h6: ['style'],
    strong: ['style'],
    del: ['style'],
    em: ['style'],
    u: ['style'],
    sub: ['style'],
    sup: ['style'],
    ul: ['style'],
    li: ['style'],
    ol: ['style'],
    span: ['style'],
    a: ['href', 'target', 'style'],
    hr: ['class']
  },
  allowedStyles: {
    '*': {
      'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/],
      'font-size': [/^\d+(?:px|em|%)$/],
      'line-height': [/^\d+(?:px|em|%)*$/],
      'color': [/rgb\((\d{1,3}), *(\d{1,3}), *(\d{1,3})\)/],
      'background-color': [/rgb\((\d{1,3}), *(\d{1,3}), *(\d{1,3})\)/],
      'margin-left': [/^\d+(?:px|em|%)$/],
      'margin-right': [/^\d+(?:px|em|%)$/],
      'margin-top': [/^\d+(?:px|em|%)$/],
      'margin-bottom': [/^\d+(?:px|em|%)$/],
      'width': [/^\d+(?:px|em|%)$/],
      'height': [/^\d+(?:px|em|%)$/]
    }
  },
  allowedSchemes: ['http', 'https', 'data']
};

const toolbars = {
  basic: [
    ['undo', 'redo'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['link'],
    ['fullScreen'],
    ['removeFormat']
  ],
  full: [
    ['undo', 'redo'],
    ['fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['align', 'list', 'lineHeight'],
    ['link'],
    ['fullScreen'],
    ['removeFormat']
  ]
}

const DraftInput = ({ label, placeholder, disabled, toolbar, input, meta }) => {
  const inputEditor = useRef(null);

  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  const handleChange = async (content) => {
    if(content) {
      const cleanHTML = sanitizeHtml(content, sanitizeOptions);
      input.onChange(emptyDraft.test(cleanHTML) ? '' : cleanHTML);
    }
  }

  return (
    <SunDraftWrapper error={showError}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <SunEditor
        ref={inputEditor}
        placeholder={placeholder}
        disable={disabled}
        setContents={input?.value}
        onChange={handleChange}
        setOptions={{
          mode: 'classic', // 'classic', 'inline', 'balloon', 'balloon-always'
          height: 'auto',
          minHeight: 150,
          maxHeight: 600,
          buttonList: toolbars[toolbar],
          formats: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
        }}
      />
    </SunDraftWrapper>
  );
}

DraftInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  toolbar: PropTypes.oneOf(['basic', 'full']),
  setLoadingImage: PropTypes.func
};

DraftInput.defaultProps = {
  toolbar: 'basic'
};

export default DraftInput;