import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, initialize, reset, FieldArray } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetUser,
  UpdateUser,
  CreateUser,
  GetCompaniesList,
} from '../../infra/requests/UsersRequests';
import {
  GetAreasList,
} from '../../infra/requests/AreasRequests';
import TextInput from '../../components/generic/inputs/TextInput';
import SelectInput from '../../components/generic/inputs/SelectInput';
import { FormContainer, BaseForm, SpinLoading, Separator } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { UserTypes } from '../../infra/utils/UserTypes';
import ImageInput from '../../components/generic/inputs/ImageInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import { CompanyTypes } from '../../infra/utils/CompanyTypes';
import { SponsorTypes } from '../../infra/utils/SponsorTypes';
import CheckboxInput from '../../components/generic/inputs/CheckboxInput';
import SelectTagsInput from '../../components/generic/inputs/SelectTagsInput';
import FilesInput from '../../components/generic/inputs/FilesInput';
import { UniversityTypes } from '../../infra/utils/UniversityTypes';
import { CycleTypes } from '../../infra/utils/CycleTypes';
import { YearTypes } from '../../infra/utils/YearTypes';
import {
  TabsContainer,
  Tab
} from './UserStyles';
import YearAccess from './components/YearAccess';
import PointsHistory from './components/PointsHistory';
import BadgesHistory from './components/BadgesHistory';

const validations = FormValidator.make({
  name: 'required',
  email: 'required',
  type: 'required'
});

class ManageUsersPage extends Component {
  state = {
    isNew: false,
    loading: true,
    type: 1,
    isStudent: false,
    areas: [],
    user: undefined,
    companies: [],
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    //Current user must edit his profile in the "My Profile" page
    if(user._id === params.id) {
      return history.push('/my-profile');
    }

    const areas = await GetAreasList();
    const companies = await GetCompaniesList();

    CloseMenu();

    if(params.id) {
      const result = await GetUser(params.id);
      dispatch(
        initialize('manage_users_form', {
          ...result.data,
        })
      );

      this.setState({ 
        loading: false,
        type: result?.data?.type,
        user: result?.data,
        isStudent: result?.data.is_student,
        isUminho: parseInt(result?.data.university) === 1 ? true : parseInt(result?.data.university) === 2 ? false : undefined,
        areas: areas?.data || [],
        companies: companies?.data || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        areas: areas?.data || [],
        companies: companies?.data || []
      });
    }
  };

  onSubmit = async (values, createNewAfter) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history,
        dispatch,
      } = this.props;
      const { isNew } = this.state;

      const payload = FlattenToFormData({ ...values, profile_set: true });
      const request = isNew ? await CreateUser(payload) : await UpdateUser(params.id, payload);

      this.setState({ loading: false });
      
      if(request.success) {
        if(createNewAfter) {
          dispatch(reset('manage_users_form'));
          return history.push('/users/add');
        } 
        else {
          return history.push('/users');
        }
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/users');
  };

  checkType = (value) => {
    this.setState({
      type: parseInt(value)
    });
  }

  checkStudent = (value) => {
    this.setState({ 
      isStudent: value?.target?.checked || false 
    });
  }

  checkUniversity = (value) => {
    this.setState({
      isUminho: parseInt(value) === 1 ? true : parseInt(value) === 2 ? false : undefined
    });
  }

  renderDetails = () => {
    const { type, isStudent, isUminho, areas, companies } = this.state;

    return (
      <React.Fragment>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Field
              component={SelectInput}
              name={'type'}
              label={'Tipo *'}
              placeholder={'Escolha o tipo'}
              type='select'
              data={UserTypes}
              dataKey={'_id'}
              dataLabel={'name'}
              execAfterChange={this.checkType}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label='Nome *'
              placeholder='Nome do utilizador'
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            <Field
              component={TextInput}
              name='email'
              type='email'
              label='Email *'
              placeholder='Email do utilizador'
            />
          </Col>
          <Col xs={24} lg={12}>
            <Field
              component={TextInput}
              name='contact'
              type='text'
              label='Contacto'
              placeholder='Contacto do utilizador'
            />
          </Col>   
        </Row>  
        {type == 3 && (
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Field
                component={SelectInput}
                name={'company'}
                label={'Empresa*'}
                placeholder={'Escolha a empresa'}
                type='select'
                data={companies}
                dataKey={'_id'}
                dataLabel={'name'}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Field
                component={TextInput}
                name='job'
                type='text'
                label='Cargo/Função'
                placeholder='Cargo/Função'
              />
            </Col>
          </Row>
        )} 
        {
          type === 2 &&
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Field
                component={TextInput}
                name='city'
                type='text'
                label='Cidade'
                placeholder='Cidade'
              />
            </Col>
          </Row>
        }
        {
          type === 2 &&
          <React.Fragment>
            <Separator/>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Field
                  component={ImageInput}
                  name='image'
                  label='Imagem do utilizador'
                  ratio={1.0}
                />
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TextAreaInput}
                  name='description'
                  label='Descrição'
                  placeholder='Breve descrição'
                  minRows={9}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24} lg={12}>
                <Field
                  component={FilesInput}
                  name='cv'
                  label='Curriculum vitae'
                  accept={'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
                />
                <div style={{ marginTop: '5px', fontSize: '13px' }}>Formatos permitidos: <strong>pdf, doc e docx</strong></div>
                <div style={{ fontSize: '13px' }}>Tamanho máximo: <strong>12MB</strong></div>
              </Col>
              <Col xs={24} lg={12}>
                <Field
                  component={TextInput}
                  name='linkedin'
                  type='text'
                  label='LinkedIn'
                  placeholder='LinkedIn'
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24}>
                <Field
                  component={CheckboxInput}
                  name={`looking_job`}
                  label={'Está à procura de emprego?'}
                  inlineCheckbox={true}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24}>
                <Field
                  component={CheckboxInput}
                  name={`is_student`}
                  label={'É estudante?'}
                  inlineCheckbox={true}
                  execAfterChange={this.checkStudent}
                />
              </Col>
            </Row>
            {
              isStudent ?
              <React.Fragment>
                <Row gutter={24}>
                  <Col xs={24} lg={12}>
                    <Field
                      component={SelectInput}
                      name={'university'}
                      label={'Universidade'}
                      placeholder={'Escolha a universidade'}
                      type='select'
                      data={UniversityTypes}
                      dataKey={'_id'}
                      dataLabel={'name'}
                      execAfterChange={this.checkUniversity}
                      allowClear={true}
                    />
                  </Col>
                  {
                    isUminho === false &&
                    <Col xs={24} lg={12}>
                      <Field
                        component={TextInput}
                        name='university_other'
                        type='text'
                        label='Nome da universidade'
                        placeholder='Nome da universidade'
                      />
                    </Col>
                  }
                </Row>
                {
                  isUminho &&
                  <React.Fragment>
                    <Row gutter={24}>
                      <Col xs={24} lg={12}>
                        <Field
                          component={SelectInput}
                          name={'cycle'}
                          label={'Ciclo de estudos'}
                          placeholder={'Escolha o ciclo'}
                          type='select'
                          data={CycleTypes}
                          dataKey={'_id'}
                          dataLabel={'name'}
                          allowClear={true}
                        />
                      </Col>
                      <Col xs={24} lg={12}>
                        <Field
                          component={TextInput}
                          name='course'
                          type='text'
                          label='Curso'
                          placeholder='Curso'
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xs={24} lg={12}>
                        <Field
                          component={SelectInput}
                          name={'year'}
                          label={'Ano Curricular'}
                          placeholder={'Escolha o ano'}
                          type='select'
                          data={YearTypes}
                          dataKey={'_id'}
                          dataLabel={'name'}
                          allowClear={true}
                        />
                      </Col>
                      <Col xs={24} lg={12}>
                        <Field
                          component={TextInput}
                          name='mecha_number'
                          type='text'
                          label='Nº mecanográfico'
                          placeholder='Nº mecanográfico'
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                }
              </React.Fragment>
              :
              <Row gutter={24}>
                <Col xs={24}>
                  <Field
                    component={TextInput}
                    name='job'
                    type='text'
                    label='Profissão'
                    placeholder='Profissão'
                  />
                </Col>
              </Row>
            }
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24}>
                <Field
                  name='areas'
                  label={'Interesses'}
                  multiple={true}
                  component={SelectTagsInput}
                  options={areas}
                  translatable={true}
                />
              </Col>
            </Row>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }

  render() {
    const { handleSubmit, pristine } = this.props;
    const { isNew, loading, user } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Utilizadores', title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values, false)),
              disabled: pristine
            },
            {
              type: 'primary',
              icon: 'file',
              text: 'Gravar & Criar novo',
              onClick: handleSubmit((values) => this.onSubmit(values, true)),
              disabled: pristine
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            }
          ]}
        />
        <FormContainer style={{ marginBottom: '50px' }}>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            {
              isNew || user.type === 1 ?
              this.renderDetails()
              :
              <TabsContainer defaultActiveKey='details'>
                <Tab
                  tab='Detalhes'
                  key='details'
                >
                  {this.renderDetails()}
                </Tab>
                <Tab
                  tab='Acesso APP'
                  key='access'
                >
                  <Row gutter={24}>
                    <Col xs={24}>
                      <FieldArray
                        component={YearAccess}
                        name='active_fo'
                      />
                    </Col>
                  </Row>
                </Tab>
                {
                  user.type === 2 &&
                  <Tab
                    tab='Pontos'
                    key='points'
                  >
                    <PointsHistory user={user}/>
                  </Tab>
                }
                {
                  user.type === 2 &&
                  <Tab
                    tab='Badges'
                    key='badges'
                  >
                    <BadgesHistory user={user}/>
                  </Tab>
                }
              </TabsContainer>
            }
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageUsersPage = reduxForm({
  form: 'manage_users_form',
  validate: validations,
})(ManageUsersPage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageUsersPage));