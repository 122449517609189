import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  GetPrizes,
  TogglePrizeState,
  DeletePrize
} from '../../infra/requests/PrizesRequests';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import BaseButton from '../../components/generic/buttons/BaseButton';

class PrizesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Prémio #',
        dataIndex: 'prize',
        width: '15%',
      },
      {
        title: 'Título',
        dataIndex: 'name',
        render: (value) => TranslateValue(value),
        width: '50%',
      },
      {
        title: 'Ações',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title={`Quer ${
                  data.active ? 'desativar' : 'ativar'
                } este prémio?`}
                onConfirm={() => this.toggleActive(data)}
              >
                <Icon style={{ marginRight: 10 }} type={data.active ? 'close-circle' : 'check-circle'} />
                {data.active ? ' Desativar' : ' Ativar'}
              </Popconfirm>
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o prémio?'
                onConfirm={() => this.removePrize(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/prizes/${record.id}`);
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });

      const result = await GetPrizes();

      this.setState({
        rows: result?.data || [],
        total: result?.data?.length || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  toggleActive = async (record) => {
    try {
      this.setState({ loading: true });
      await TogglePrizeState(record._id, { active: !record.active });
      await this.updateDataTable();
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  removePrize = async (data) => {
    try {
      await DeletePrize(data.id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { history } = this.props;
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Prémios</PageTitle>
            {`Prémios`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/prizes/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Prémios inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default PrizesPage;