import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  GetSpeakers,
  DeleteSpeaker
} from '../../infra/requests/SpeakersRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import SpeakersFilters from './SpeakersFilters';

class SpeakersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '20%',
      },
      {
        title: 'Empresa',
        dataIndex: 'company',
        width: '20%',
      },
      {
        title: 'Cargo',
        dataIndex: 'job_title',
        width: '20%',
      },
      {
        title: 'Ações',
        render: (data) => (
          <TableButton error onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              placement='topRight'
              title='Tem a certeza que quer remover o orador?'
              onConfirm={() => this.removeSpeaker(data)}
            >
              <Icon style={{ marginRight: 10 }} type='delete' />
              {'Remover'}
            </Popconfirm>
          </TableButton>
        ),
      },
    ],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/speakers/${record.id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetSpeakers(currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
      });
      this.setState({ ready: true, loading: false });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  removeSpeaker = async (data) => {
    try {
      await DeleteSpeaker(data.id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { history } = this.props;
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Oradores</PageTitle>
            {`Oradores`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/speakers/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <SpeakersFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Oradores inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default SpeakersPage;