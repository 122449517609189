import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/generic/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
} from '../../styles/BasicStyles';
import {
  TypeLabel
} from './EventsStyles';
import { 
  GetEvents, 
  DeleteEvent, 
  ToggleEventState 
} from '../../infra/requests/EventsRequests';
import { 
  GetAreasList
} from '../../infra/requests/AreasRequests';
import { 
  GetSpeakersList
} from '../../infra/requests/SpeakersRequests';
import { 
  GetInfo
} from '../../infra/requests/EventInfoRequests';
import BaseButton from '../../components/generic/buttons/BaseButton';
import EventsFilters from './EventsFilters';
import { getEventType, getEventSubType } from '../../infra/utils/EventTypes';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import moment from 'moment';
import EventParticipants from './components/EventParticipants';

class EventsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: false,
    columns: [
      {
        title: 'Tipo',
        dataIndex: 'type',
        render: (value) => this.renderType(value),
        width: '12%'
      },
      {
        title: 'Subtipo',
        dataIndex: 'subtype',
        render: (value) => this.renderSubtype(value),
        width: '12%'
      },
      {
        title: 'Nome',
        dataIndex: 'title',
        render: (value) => TranslateValue(value),
        width: '20%'
      },
      {
        title: 'Data e Hora',
        key: 'date',
        render: (data) => this.renderDate(data),
        width: '18%'
      },
      {
        title: 'Localização',
        dataIndex: 'location',
        width: '12%'
      },
      {
        title: "Utilizadores registados",
        dataIndex: "registered_users",
        width: "12%",
      },
      {
        title: '',
        render: (data) => (
          <React.Fragment>
            <TableButton onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title={
                  data.active
                    ? 'Quer desativar este evento?'
                    : 'Quer publicar este evento?'
                }
                onConfirm={() => this.toggleEvent(data)}
              >
                <Icon type={data.active ? 'close-circle' : 'check-circle'} />
                {data.active ? ' Desativar' : ' Publicar'}
              </Popconfirm>
            </TableButton>
            <TableButton error onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza que quer remover o evento?'
                onConfirm={() => this.removeEvent(data)}
              >
                <Icon style={{ marginRight: 10 }} type='delete' />
                {'Remover'}
              </Popconfirm>
            </TableButton>
          </React.Fragment>
        ),
      },
    ],
    rows: [],
    total: 0,
    areas: [],
    speakers: [],
    eventInfo: undefined
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/events/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  removeEvent = async (data) => {
    try {
      this.setState({ loading: true });
      await DeleteEvent(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  toggleEvent = async (record) => {
    try {
      this.setState({ loading: true });
      await ToggleEventState(record._id, { active: !record.active });
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    try {
      this.setState({ loading: true });
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetEvents(currentPage, pageSize, filters);
      const areas = await GetAreasList();
      const speakers = await GetSpeakersList();
      const eventInfo = await GetInfo(process.env.REACT_APP_EVENT_INFO_ID);

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        areas: areas?.data || [],
        speakers: speakers?.data || [],
        eventInfo: eventInfo?.data || undefined
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderDate = (data) => {
    const { eventInfo } = this.state;

    let dateText = '';

    if(data?.date) {
      const date = eventInfo.dates[data.date - 1] || ''
      if(date) dateText = moment.utc(date).format('DD/MM/YYYY');
    }
    if(data?.start_time) {
      if(dateText) dateText = `${dateText}, ${moment.utc(data.start_time).format('HH:mm')}`;
      else dateText = moment.utc(data.start_time).format('HH:mm');
    }
    if(data?.end_time) {
      if(dateText) dateText = `${dateText} - ${moment.utc(data.end_time).format('HH:mm')}`;
      else dateText = moment.utc(data.end_time).format('HH:mm');
    }

    return dateText;
  }

  renderType = (value) => {
    const currentType = getEventType(value);

    return (
      <TypeLabel
        bgColor={currentType.bgColor}
        fontColor={currentType.fontColor}
      >
        {currentType.name}
      </TypeLabel>
    );
  };

  renderSubtype = (value) => {
    const currentType = getEventSubType(value);

    return (
      <TypeLabel
        bgColor={currentType.bgColor}
        fontColor={currentType.fontColor}
      >
        {currentType.name}
      </TypeLabel>
    );
  };

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, areas, speakers, eventInfo } = this.state;
    const { history } = this.props;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>Lista de Eventos</PageTitle>
            {`Eventos`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Criar'}
              onClick={() => history.push('/events/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <EventsFilters 
            areas={areas}
            speakers={speakers}
            eventInfo={eventInfo}
            queryChange={this.handleFilterChange}
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={'Não existem Eventos inseridos!'}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            expandedRowRender={(item) => (
              <EventParticipants event={item?._id} />
            )}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default EventsPage;