import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetPointsItem,
  UpdatePoints
} from '../../infra/requests/PointsRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import {
  Title
} from './PointsStyles';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import FormValidator from '../../infra/services/validations/FormValidator';
import NumberInput from '../../components/generic/inputs/NumberInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import TextInput from '../../components/generic/inputs/TextInput';

const validations = FormValidator.make({
  points: 'required'
});

class ManagePointsPage extends Component {
  state = { 
    loading: true,
    info: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu
    } = this.props;
    
    CloseMenu();

    const result = await GetPointsItem(params.id);
    dispatch(
      initialize('manage_points_form', {
        ...result.data,
      })
    );

    this.setState({ 
      info: result.data,
      loading: false 
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const payload = FlattenToFormData(values);
      const { success } = await UpdatePoints(params.id, payload);

      if (success) return history.push('/points');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/points');
  };

  render() {
    const { handleSubmit, pristine, activeLanguage, languages } = this.props;
    const { loading, info } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={['Pontos', 'Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
            {
              type: 'default',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
                <Title>Título: <span>{info?.title?.pt}</span></Title>
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
                {
                  languages?.length > 1 &&
                  <DefaultLanguageTab>
                    {GetTranslationDescription(activeLanguage?.code)}
                  </DefaultLanguageTab>
                }
                <Field
                  component={TextInput}
                  name={`description.${activeLanguage?.code}`}
                  type='text'
                  label={'Descrição'}
                  placeholder={'Descrição do badge'}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '15px' }}>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
                <Field
                  component={NumberInput}
                  name='points'
                  min={0}
                  label='Pontos *'
                  placeholder='Pontos'
                />
              </Col>
            </Row>
            {
              info?.has_badge &&
              <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 6 }}>
                  <Field
                    component={ImageInput}
                    name='badge'
                    label='Badge'
                    ratio={1}
                    noCrop={true}
                  />
                </Col>
              </Row>
            }
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagePointsPage = reduxForm({
  form: 'manage_points_form',
  validate: validations
})(ManagePointsPage);

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(null, mapActionToProps)(ManagePointsPage));