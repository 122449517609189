import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import { 
  PageContainer, 
  SpinLoading
} from '../../styles/BasicStyles';
import { RowDashboard, ColDashboard, Block, Title, TitleData, LeaderBoardContent, TitleYear, TitleSmall, TitleDataSmall, ColDashboardSmall } from './DashboardStyles';
import { TableHeader, TableFirstColumn, TableMiddleColumn, TableLastColumn, ImageRanking, ImageRankingEmpty, Number, UserImage, UserName, UserPoints, TableLine } from '../leaderboard/LeaderBoardStyles';
import { MenuBorderColor, ThirdColour } from '../../styles/Colours';
const { REACT_APP_IMAGES_URL } = process.env;

class DashboardPage extends Component {
  state = {
    ready: false
  };

  componentDidMount() {
    this.setState({
      ready: true
    });
  }

  GetImageURL = (image) => {
    const photoURL =
      image && image.file ? REACT_APP_IMAGES_URL + image.file : undefined
    return photoURL
  };

  render() {
    const { ready } = this.state;
    const { info } = this.props;

    if(!ready) return <SpinLoading />;
    const year = moment.utc().year();
    // const lastYear = moment.utc().subtract(1, 'year').year();

    const participants = info.dashboard?.users?.participants
    const companies = info.dashboard?.companies
    const volunteers = info.dashboard?.users?.volunteers
    const collaborators = info.dashboard?.users?.collaborators
    const companiesYear = []
    // const companies2022 = []
    companies.forEach(element => {
      if (element.active_fo?.find(elem => elem.year === year && !!elem.active))
      companiesYear.push(element)

      // if (element.active_fo?.find(elem => elem.year === lastYear && !!elem.active))
      //   companies2022.push(element)
    });

    return (
      <PageContainer>
        <RowDashboard gutter={[16, 16]} style={{backgroundColor: MenuBorderColor, borderRadius: 8}}>
          <TitleYear>{year}</TitleYear>
          <ColDashboard xs={12} md={6} lg={6}>
            <Block>
              <Title>Empresas</Title>
              <TitleData>{companiesYear.length || 0}</TitleData>
            </Block>
          </ColDashboard>
          <ColDashboard xs={12} md={6} lg={6}>
            <Block>
              <Title>Colaboradores</Title>
              <TitleData>{collaborators?.find(elem => elem.year === year)?.users || 0}</TitleData>
            </Block>
          </ColDashboard>
          <ColDashboard xs={12} md={6} lg={6}>
            <Block>
              <Title>Participantes</Title>
              <TitleData>{participants?.find(elem => elem.year === year)?.users || 0}</TitleData>
            </Block>
          </ColDashboard>
          <ColDashboard xs={12} md={6} lg={6}>
            <Block>
              <Title>Voluntários</Title>
              <TitleData>{volunteers?.find(elem => elem.year === year)?.users || 0}</TitleData>
            </Block>
          </ColDashboard>
        </RowDashboard>
        {/* <RowDashboard gutter={[16, 16]} style={{backgroundColor: MenuBorderColor, borderRadius: 8, marginTop: 20}}>
          <TitleYear>2022</TitleYear>
          <ColDashboardSmall xs={12} md={6} lg={6}>
            <Block>
              <TitleSmall>Empresas</TitleSmall>
              <TitleDataSmall>{companies2022.length || 0}</TitleDataSmall>
            </Block>
          </ColDashboardSmall>
          <ColDashboardSmall xs={12} md={6} lg={6}>
            <Block>
              <TitleSmall>Colaboradores</TitleSmall>
              <TitleDataSmall>{collaborators?.find(elem => elem.year === lastYear)?.users || 0}</TitleDataSmall>
            </Block>
          </ColDashboardSmall>
          <ColDashboardSmall xs={12} md={6} lg={6}>
            <Block>
              <TitleSmall>Participantes</TitleSmall>
              <TitleDataSmall>{participants?.find(elem => elem.year === lastYear)?.users || 0}</TitleDataSmall>
            </Block>
          </ColDashboardSmall>
          <ColDashboardSmall xs={12} md={6} lg={6}>
            <Block>
              <TitleSmall>Voluntários</TitleSmall>
              <TitleDataSmall>{volunteers?.find(elem => elem.year === lastYear)?.users || 0}</TitleDataSmall>
            </Block>
          </ColDashboardSmall>
        </RowDashboard> */}
        <RowDashboard gutter={[16, 16]} style={{marginTop: 50}}>
          <Title>Ranking</Title>
        </RowDashboard>
        <RowDashboard gutter={[16, 16]} style={{ backgroundColor: MenuBorderColor, borderRadius: 8}}>
        <LeaderBoardContent>
          <TableHeader>
            <TableFirstColumn>Ranking</TableFirstColumn>
            <TableMiddleColumn>Participante</TableMiddleColumn>
            <TableLastColumn>Pontos</TableLastColumn>
          </TableHeader>
          {info.dashboard.leaderboard && info.dashboard.leaderboard.map((user, i) => (
          <TableLine key={i}>
            <TableFirstColumn>
              {i < 3 ? (<ImageRanking src={
                i === 0 ? require('../../assets/icons/first.png')
                : i === 1 ? require('../../assets/icons/second.png')
                : i === 2 ? require('../../assets/icons/third.png')
                : null
              }/>) : (
                <ImageRankingEmpty />
              )}
              <Number>{i + 1}</Number>
            </TableFirstColumn>
            <TableMiddleColumn>
              <UserImage src={
                this.GetImageURL(user.image) ||
                require('../../assets/icons/default_user.png')
              }/>
              <UserName>{user.name}</UserName>
            </TableMiddleColumn>
            <TableLastColumn>
              <UserPoints>{user.points} pt</UserPoints>
            </TableLastColumn>
          </TableLine>))}
        </LeaderBoardContent>
        </RowDashboard>
      </PageContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  info: state.info
});

export default connect(mapStateToProps)(DashboardPage);