import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'redux-form';
import CheckboxInput from '../../../components/generic/inputs/CheckboxInput';
import moment from 'moment';
import {
  TableContainer,
  TableHeader,
  TableTitle,
  TableContent,
  TableRow,
  TableValue
} from '../UserStyles';

const YearAccess = ({ fields }) => {
  useEffect(() => {
    const years = fields.getAll() || [];
    const currentYear = moment.utc().year();

    //We need to add all the years between the last saved year in the database and the current year
    if(years.length > 0) {
      const lastInsertedYear = years[0].year;

      if(lastInsertedYear < currentYear) {
        for(let i = lastInsertedYear + 1; i <= currentYear; i++) {
          fields.unshift({
            year: i,
            active: false
          });
        }
      }
    }
    else {
      fields.unshift({
        year: currentYear,
        active: false
      });
    }
  }, [fields]);
  
  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <TableContainer>
            <TableHeader>
              <TableTitle>Ano</TableTitle>
              <TableTitle>Ativo?</TableTitle>
            </TableHeader>
            <TableContent>
              {
                fields.map((field, index) => 
                  <TableRow key={index}>
                    <TableValue>
                      { fields.get(index).year }
                    </TableValue>
                    <TableValue>
                      <Field
                        component={CheckboxInput}
                        name={`${field}.active`}
                        inlineCheckbox={true}
                      /> 
                    </TableValue>
                  </TableRow>
                )
              }
            </TableContent>
          </TableContainer>
        </Col>
      </Row>
      
    </React.Fragment>  
  );
};

export default YearAccess;